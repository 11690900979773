import { Form, FormInstance, Input } from "antd";
import { CSSProperties } from "react";

interface INewHistory {
  form: FormInstance<any>;
  newHistoryForm: {
    variant: "filled";
    wrapperCol: { span: number };
    layout: "horizontal";
    style: CSSProperties;
    rules: { required: boolean; message: string };
  };
}

export default function NewHistory({ form, newHistoryForm }: INewHistory) {
  return (
    <Form form={form} {...newHistoryForm}>
      <Form.Item rules={[newHistoryForm.rules]} name="writer">
        <Input placeholder="Writer" allowClear />
      </Form.Item>

      <Form.Item rules={[newHistoryForm.rules]} name="content">
        <Input.TextArea
          placeholder="Content"
          allowClear
          style={{ resize: "none" }}
        />
      </Form.Item>

      <Form.Item
        rules={[
          newHistoryForm.rules,
          { pattern: /^http:|https:/, message: "https: or http:" },
        ]}
        name="link">
        <Input placeholder="Link" allowClear maxLength={100} />
      </Form.Item>
    </Form>
  );
}
