import DvrIcon from "@mui/icons-material/Dvr";
import { colorPallete } from "../../../../config";

export function ActionMultiparameter() {
  return <DvrIcon style={{ color: "white" }} />;
}

export function DisableMultiparameter() {
  return <DvrIcon style={{ color: colorPallete.gray }} />;
}
