import { Input, Radio, Select } from "antd";
import { InputRef, SearchProps } from "antd/es/input";
import React, { useRef } from "react";
import styled from "styled-components";
import { colorPallete } from "../../../config";
import { SearchCancel } from "../../../config/image";
import { ISearchParams } from "../../../config/interfaces";
import { filterChange } from "../../../util/tableUtil";
import Button from "../../atom/Button";

const buttonProps = {
  tooltip: {
    title: "검색 초기화",
    placement: "bottom" as "bottom",
  },
  iconButton: {
    style: {
      backgroundColor: colorPallete.orange,
      border: "none",
      marginRight: ".625rem",
    },
    icon: <SearchCancel />,
    onClick: () => console.log("clear"),
  },
};

const Styles = {
  SearchContainer: styled(Radio.Group)`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  `,
  SearchInput: styled(Input.Search)`
    width: 20%;
  `,
};

interface ISearchBar {
  searchFilter?: string;
  setSearchFilter: React.Dispatch<React.SetStateAction<string>>;
  searchParams: ISearchParams[];
  setSearchResult: React.Dispatch<React.SetStateAction<string>>;
}

interface IInputCheck {
  searchFilter?: string;
  onSelectSearch: (value: any) => void;
  onSearch: SearchProps["onSearch"];
  inputRef: React.RefObject<InputRef>;
}

const InputCheck = ({
  searchFilter,
  onSelectSearch,
  onSearch,
  inputRef,
}: IInputCheck) => {
  if (
    searchFilter?.slice(1, -1) === "operating_status" ||
    searchFilter?.slice(1, -1) === "state" ||
    searchFilter?.slice(1, -1) === "wifi_state" ||
    searchFilter?.slice(1, -1) === "bluetooth_state"
  ) {
    return (
      <Select
        onChange={onSelectSearch}
        style={{ width: "20%" }}
        options={[
          {
            label: "ON",
            value: true,
          },
          {
            label: "OFF",
            value: false,
          },
        ]}
      />
    );
  } else if (
    searchFilter?.slice(1, -1) === "code_quantity" ||
    searchFilter?.slice(1, -1) === "device_id" ||
    searchFilter?.slice(1, -1) === "board_id"
  ) {
    return (
      <Styles.SearchInput
        onSearch={onSearch}
        addonBefore={searchFilter?.slice(1, -1)}
        ref={inputRef}
        type="number"
      />
    );
  } else if (searchFilter?.slice(1, -1) === "product_status") {
    return (
      <Select
        onChange={onSelectSearch}
        style={{ width: "20%" }}
        options={[
          {
            label: "ordered",
            value: "ordered",
          },
          {
            label: "developing",
            value: "developing",
          },
          {
            label: "completed",
            value: "completed",
          },
          {
            label: "shipped",
            value: "shipped",
          },
        ]}
      />
    );
  } else if (searchFilter?.slice(1, -1) === "type") {
    return (
      <Select
        onChange={onSelectSearch}
        style={{ width: "20%" }}
        options={[
          {
            label: "buoy",
            value: "buoy",
          },
          {
            label: "portable",
            value: "portable",
          },
        ]}
      />
    );
  } else if (searchFilter === "") {
    return (
      <Styles.SearchInput
        onSearch={onSearch}
        addonBefore={searchFilter?.slice(1, -1)}
        ref={inputRef}
        value=""
        disabled
      />
    );
  } else {
    return (
      <Styles.SearchInput
        onSearch={onSearch}
        addonBefore={searchFilter?.slice(1, -1)}
        ref={inputRef}
        type="text"
      />
    );
  }
};

export default function SearchBar({
  searchFilter,
  setSearchFilter,
  setSearchResult,
  searchParams,
}: ISearchBar) {
  const inputRef = useRef<InputRef>(null);

  const onSearch: SearchProps["onSearch"] = (value, _e) => {
    setSearchResult(searchFilter + value);
  };

  const onSelectSearch = (value: any) => {
    setSearchResult(searchFilter + value);
  };

  buttonProps.iconButton.onClick = () => {
    setSearchResult("");
    setSearchFilter("");
  };

  return (
    <Styles.SearchContainer
      onChange={(e) => filterChange(e, setSearchFilter)}
      value={searchFilter}>
      <Button {...buttonProps} />

      {searchParams.map((res) => (
        <Radio key={res.value} value={res.value} children={res.children} />
      ))}

      <InputCheck
        searchFilter={searchFilter}
        onSelectSearch={onSelectSearch}
        onSearch={onSearch}
        inputRef={inputRef}
      />
    </Styles.SearchContainer>
  );
}
