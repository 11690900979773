import { Icon, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colorPallete } from "../../../config";
import { IAtomProps } from "../../../page/Sidebar/interface";

const Styles = {
  Li: styled(ListItemText)<{ $style?: any; $pathname: string; to?: string }>`
    ${(props) => props.$style};
    color: ${(props) =>
      props.$pathname === props.to ||
      props.$pathname === "Manual" ||
      props.$pathname === "Dashboard"
        ? "white"
        : colorPallete.gray};
  `,
};

export default function Anchor({
  pathname,
  to,
  pageName,
  actionIcon,
  disableIcon,
  sx,
  target,
  style,
}: IAtomProps) {
  return (
    <ListItem component={Link} to={to} target={target && target}>
      <ListItemIcon sx={sx}>
        <Icon component={pathname === to ? actionIcon : disableIcon} />
      </ListItemIcon>
      <Styles.Li
        primary={pageName}
        $pathname={pathname}
        to={to}
        $style={style && style}
      />
    </ListItem>
  );
}
