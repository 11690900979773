import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: #EFF3F8;
    margin: 0;
    font-size: calc(10px + 1vmin);
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
  }
`;
