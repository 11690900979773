import { Spin } from "antd";
import { CSSProperties } from "react";

interface ILoadingAtom {
  loading: { wrapper: { style: CSSProperties }; indicator: { size: "large" } };
}

export default function Loading({ loading }: ILoadingAtom) {
  return (
    <div {...loading.wrapper}>
      <Spin {...loading.indicator} />
    </div>
  );
}
