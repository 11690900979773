import SolarPowerIcon from "@mui/icons-material/SolarPower";
import { colorPallete } from "../../../../config";

export function ActionSolarPanel() {
  return <SolarPowerIcon style={{ color: "white" }} />;
}

export function DisableSolarPanel() {
  return <SolarPowerIcon style={{ color: colorPallete.gray }} />;
}
