import MicExternalOnIcon from "@mui/icons-material/MicExternalOn";
import { colorPallete } from "../../../../config";

export function ActionSensor() {
  return <MicExternalOnIcon style={{ color: "white" }} />;
}

export function DisableSensor() {
  return <MicExternalOnIcon style={{ color: colorPallete.gray }} />;
}
