import { Button, Modal } from "antd";
import { LatLngExpression } from "leaflet";
import MapView from "../MapView";

interface IMapViewModal {
  modalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  locationStatus: any;
  locationError: any;
  locationData: any;
  center: LatLngExpression;
  locationIsFetching: boolean;
  molecule: any;
}

const MapViewModal = ({
  modalOpen,
  handleOk,
  handleCancel,
  locationStatus,
  locationError,
  locationData,
  center,
  locationIsFetching,
  molecule,
}: IMapViewModal) => {
  return (
    <Modal
      width={1000}
      open={modalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[<Button key="Close" children="Close" onClick={handleCancel} />]}>
      <MapView
        modalOpen={modalOpen}
        locationStatus={locationStatus}
        locationError={locationError}
        data={locationData}
        center={center}
        locationIsFetching={locationIsFetching}
        {...molecule}
      />
    </Modal>
  );
};

export default MapViewModal;
