import { Form, Table } from "antd";
import React, { SetStateAction, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useBuoy, useTotalDevice } from "../../api/getData";
import {
  useAddData,
  useDeleteData,
  useUpdateData,
} from "../../api/mutationData";
import { EditableCell } from "../../components/molecule";
import { TableTemplate } from "../../components/template";
import { handleAdd } from "../../util/tableUtil";
import Columns from "./Columns";
import { deviceProps, initialRow, newData, searchParams } from "./data";
import { IMCU, IUpdateMCU } from "./interface";

const path = "mcu";

export default function MCU() {
  const location = useLocation();
  const queryKey = location.pathname;

  const [form] = Form.useForm();

  const [pageNum, setPageNum] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [searchResult, setSearchResult] = useState("");

  const { status, data, error, isFetching, refetch }: any = useBuoy(
    pageNum,
    path,
    queryKey,
    searchResult
  );
  const {
    data: totalData,
    isFetching: totalFetching,
    refetch: totalRefetch,
  }: any = useTotalDevice(path);
  const { mutate: deleteData } = useDeleteData(setErrorMessage, queryKey);
  const { mutate: addData } = useAddData(setErrorMessage, queryKey);
  const { mutate: updateData } = useUpdateData(setErrorMessage, queryKey);

  const [rows, setRows] = useState(data);
  const [editingKey, setEditingKey] =
    useState<SetStateAction<string | number>>("");
  const [addMode, setAddMode] = useState(false);
  const [totalDataLength, setTotalDataLength] = useState(10);

  const isEditing = (record: Partial<IMCU>) => record.key === editingKey;

  useEffect(() => {
    setRows(isFetching ? [] : data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  useEffect(() => {
    if (addMode) {
      setRows([initialRow, ...data]);
    }
  }, [addMode, data]);

  useEffect(() => {
    if (totalData % 10 !== 0) {
      setTotalDataLength(Math.floor(totalData / 10 + 1) * 10);
      totalRefetch();
    } else {
      setTotalDataLength(Math.floor(totalData / 10) * 10);
      setPageNum(1);
      totalRefetch();
    }
  }, [totalData, totalRefetch, isFetching]);

  useEffect(() => {
    refetch();
  }, [pageNum, refetch, searchResult]);

  const edit = (record: IMCU & { key: React.Key }) => {
    form.setFieldsValue({
      ...record,
      fk_serial_number: record.board?.serial_number,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
    setRows(data);
    setAddMode(false);
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as IUpdateMCU;
      const newData = [...rows];
      const index = newData.findIndex((item) => key === item.key);
      const old_serial_number = newData[index].serial_number;

      if (addMode) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });

        const newAddRow = {
          ...newData[0],
          path: path,
        };

        if (newAddRow.fk_serial_number === "") {
          newAddRow.fk_serial_number = null;
        }

        addData(newAddRow);
      } else {
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
          });
          const newRow = {
            ...row,
            path: path,
            old_serial_number: old_serial_number,
          };

          if (newRow.fk_serial_number === "") {
            newRow.fk_serial_number = null;
          }

          setRows(newData);
          updateData(newRow);
          setEditingKey("");
        }
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }

    setAddMode(false);
  };

  const mapColumns = (col: any) => {
    const inputType = (dataIndex: string) => {
      let type;
      if (dataIndex === "board_id") {
        type = "number";
      } else if (dataIndex === "warehousing_date") {
        type = "date";
      } else if (
        dataIndex === "state" ||
        dataIndex === "wifi_state" ||
        dataIndex === "bluetooth_state"
      ) {
        type = "select";
      } else {
        type = "text";
      }
      return type;
    };

    if (!col.editable) {
      return col;
    }

    const newCol = {
      ...col,
      onCell: (record: IMCU) => ({
        record,
        inputType: inputType(col.dataIndex),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        addMode: addMode,
      }),
    };

    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }

    return newCol;
  };

  const newColumns = Columns({
    isEditing,
    save,
    cancel,
    editingKey,
    edit,
    deleteData,
    rows,
    path,
    addMode,
  }).map(mapColumns);

  return (
    <TableTemplate
      {...deviceProps}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      handleAdd={handleAdd}
      form={form}
      initialRow={initialRow}
      newData={newData}
      addData={addData}
      editingKey={editingKey}
      fetching={isFetching || totalFetching}
      addMode={addMode}
      status={status}
      error={error}
      setAddMode={setAddMode}
      searchFilter={searchFilter}
      setSearchFilter={setSearchFilter}
      setSearchResult={setSearchResult}
      searchParams={searchParams}
      tableChildren={
        <Table
          bordered
          dataSource={rows}
          columns={newColumns}
          rowClassName={() => "editable-row"}
          pagination={{
            current: pageNum,
            total: totalDataLength,
            onChange: (value) => {
              cancel();
              setPageNum(value);
            },
          }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          scroll={{ y: "65vh" }}
        />
      }
    />
  );
}
