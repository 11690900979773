import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import { colorPallete } from "../../../../config";

export function ActionBoard() {
  return <DeveloperBoardIcon style={{ color: "white" }} />;
}

export function DisableBoard() {
  return <DeveloperBoardIcon style={{ color: colorPallete.gray }} />;
}
