import { colorPallete } from "../../config";

const buttonStyleProps = {
  // display: "flex",
};

const titleStyleProps = {
  wrapper: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    marginBottom: "-1.25rem",
  },
  main: {
    fontWeight: "bolder",
    fontSize: "1.5625rem",
    display: "inline-block",
    color: colorPallete.primary,
    textDecoration: "underline",
  },
  sub: {
    fontWeight: "bolder",
    marginTop: 8,
    marginBottom: 8,
    cursor: "pointer",
    display: "inline-block",
    fontSize: "1.25rem",
  },
};

const styleProps = {
  templateContainer: {
    width: "85%",
    margin: "0 auto",
    height: "100vh",
    overflowY: "auto" as "auto",
    justifyContent: "center",
    alignItems: "center",
  },
  flexWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  tableWrapper: {
    margin: "0 auto",
    marginBottom: "1.875rem",
    marginTop: "1.25rem",
    width: "100%",
  },
  tableStyle: {
    width: "100%",
    margin: "0 auto",
  },
  buttonWrapper: {
    display: "flex",
    gap: 20,
  },
  alert: {
    marginTop: "0.9375rem",
    marginBottom: "-1.25rem",
  },
  errorMsg: {
    margin: "0 auto",
  },
  loading: {
    margin: "0 auto",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    height: "80%",
    alignItems: "center",
  },
};

export const detailProps = {
  template: {
    container: { style: styleProps.templateContainer },
    molecule: {
      map: {
        zoom: 13,
        attribution:
          '&copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        tileUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      },
      flexWrapper: { style: styleProps.flexWrapper },
      tableWrapper: { style: styleProps.tableWrapper },
      titleWrapper: { style: titleStyleProps.wrapper },
      buttonWrapper: { style: styleProps.buttonWrapper },
      atom: {
        table: {
          style: styleProps.tableStyle,
        },
        mainTitle: {
          style: titleStyleProps.main,
          children: "",
        },
        title: {
          style: titleStyleProps.sub,
          children: "",
        },
        alert: {
          style: styleProps.alert,
          type: "error" as "error",
        },
        errorMsg: {
          style: styleProps.errorMsg,
        },
        historyButton: {
          style: buttonStyleProps,
          type: "primary" as "primary",
          children: "Show History",
          onClick: () => console.log("Show History"),
        },
        expandButton: {
          style: buttonStyleProps,
          type: "primary" as "primary",
          children: "Expand All",
          onClick: () => console.log("Expand All"),
        },
        foldButton: {
          style: buttonStyleProps,
          type: "primary" as "primary",
          children: "Fold All",
          onClick: () => console.log("Fold All"),
        },
        loading: {
          wrapper: {
            style: styleProps.loading,
          },
          indicator: { size: "large" as "large" },
        },
        createHistory: {
          tooltip: {
            title: "저장",
            placement: "bottom" as "bottom",
          },
          iconButton: {
            style: {
              border: "none",
            },
            type: "primary" as "primary",
            children: "Create",
            onClick: () => console.log("create"),
          },
        },
        deleteHistory: {
          popconfirm: {
            title: "삭제하시겠습니까?",
            onConfirm: () => console.log("delete"),
          },
          tooltip: {
            title: "삭제",
            placement: "bottom" as "bottom",
          },
          iconButton: {
            style: {
              border: "none",
              color: colorPallete.red,
            },
            type: "text" as "text",
            children: "Delete",
          },
        },
        historyItem: {
          wrapper: { style: { alignItems: "center", display: "flex" } },
          writer: { style: { fontWeight: "bold", paddingRight: 10 } },
          date: { style: { paddingRight: 10 } },
          tooltip: { placement: "bottom" as "bottom" },
          link: {
            style: { paddingRight: 10 },
            children: "Go To Link",
            target: "_blank" as "_blank",
          },
        },
        newHistoryForm: {
          variant: "filled" as "filled",
          wrapperCol: { span: 14 },
          layout: "horizontal" as "horizontal",
          style: { maxWidth: 600 },
          rules: { required: true, message: "Required" },
        },
        modalItem: {
          modal: { title: "History", okText: "Create", width: 1000 },
          newTimeline: { style: { paddingTop: 15, height: 200 } },
          timeline: { style: { height: 400, overflowY: "auto" } },
        },
      },
    },
  },
};
