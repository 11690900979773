import { Badge } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { TableButtons } from "../../components/molecule";
import { IColumns } from "../../config/interfaces";
import { handleDelete } from "../../util/tableUtil";
import { ISim } from "./interface";

export default function Columns({
  isEditing,
  save,
  cancel,
  editingKey,
  edit,
  deleteData,
  rows,
  path,
  addMode,
}: IColumns) {
  return [
    {
      title: "ID",
      dataIndex: "key",
      key: "key",
      width: "5%",
      editable: false,
      sorter: {
        compare: (a: ISim, b: ISim) => Number(a.key) - Number(b.key),
      },
    },
    {
      title: "제품명",
      dataIndex: "name",
      key: "name",
      editable: true,
    },
    {
      title: "개통일",
      dataIndex: "activate_date",
      key: "activate_date",
      editable: true,
      render: (_: any, record: ISim) => {
        const localeDate = dayjs(record.activate_date).locale("ko");
        const formattingDate = localeDate.format("YYYY-MM-DD");

        return `${formattingDate}`;
      },
    },
    {
      title: "제품 상태",
      dataIndex: "state",
      key: "state",
      editable: true,
      render: (_: any, record: ISim) => (
        <Badge
          size="default"
          status={record.state ? "success" : "error"}
          text={record.state ? "ON" : "OFF"}
        />
      ),
    },
    {
      title: "IMSI",
      dataIndex: "imsi",
      key: "imsi",
      editable: false,
    },
    {
      title: "Board 시리얼 번호",
      dataIndex: "fk_serial_number",
      key: "fk_serial_number",
      editable: true,
      addMode: addMode,
      render: (_: any, record: ISim) => record.board?.serial_number,
    },
    {
      title: "편집",
      dataIndex: "Action",
      width: "6%",
      align: "center" as const,
      render: (_: any, record: ISim) => (
        <TableButtons
          isEditing={isEditing}
          record={record}
          editingKey={editingKey}
          path={path}
          deleteData={deleteData}
          edit={edit}
          save={save}
          cancel={cancel}
          handleDelete={handleDelete}
        />
      ),
    },
  ];
}
