import { RadioChangeEvent } from "antd";
import { IAdd } from "../config/interfaces";

export const handleAdd = async ({
  form,
  initialRow,
  newData,
  setErrorMessage,
  addData,
  setAddMode,
}: IAdd) => {
  form.setFieldsValue({ ...initialRow });

  try {
    // addData(newData);
    setAddMode(true);
  } catch (errInfo: any) {
    setErrorMessage(errInfo);
  }
};

export const handleDelete = ({ path, serial_number, deleteData }: any) => {
  const deleteConfig = { path: path, serial_number: serial_number };
  deleteData(deleteConfig);
};

export const inputType = (dataIndex: string) => {
  let type;
  if (
    dataIndex === "code_quantity" ||
    dataIndex === "device_id" ||
    dataIndex === "board_id" ||
    dataIndex === "voltage" ||
    dataIndex === "electric_current"
  ) {
    type = "number";
  } else if (
    dataIndex === "expected_delivery_date" ||
    dataIndex === "shipping_date" ||
    dataIndex === "warranty" ||
    dataIndex === "warehousing_date"
  ) {
    type = "date";
  } else if (
    dataIndex === "operating_status" ||
    dataIndex === "type" ||
    dataIndex === "product_status" ||
    dataIndex === "state" ||
    dataIndex === "wifi_state" ||
    dataIndex === "bluetooth_state"
  ) {
    type = "select";
  } else {
    type = "text";
  }
  return type;
};

export const filterChange = (
  e: RadioChangeEvent,
  setSearchFilter: React.Dispatch<React.SetStateAction<string>>
) => {
  setSearchFilter(e.target.value);
};
