import { Button } from "antd";
import { CSSProperties } from "react";
import { IDetail } from "../../../config/interfaces";
import { Title } from "../../atom";

interface ITitleMolecule extends IDetail {
  titleWrapper: { style: CSSProperties };
  buttonWrapper: { style: CSSProperties };
  atom: {
    loading: {
      wrapper: { style: CSSProperties };
      indicator: { size: "large" };
    };
    mainTitle: {
      style: CSSProperties;
      children: "";
    };
    historyButton: {
      style: CSSProperties;
      type: "primary";
      children: string;
      onClick: () => void;
    };
    expandButton: {
      style: CSSProperties;
      type: "primary";
      children: string;
      onClick: () => void;
    };
    foldButton: {
      style: CSSProperties;
      type: "primary";
      children: string;
      onClick: () => void;
    };
  };
}

export default function DetailTitleSection({
  titleWrapper,
  buttonWrapper,
  atom,
  allOpen,
}: ITitleMolecule) {
  return (
    <div {...titleWrapper}>
      <Title {...atom.mainTitle} />
      <div {...buttonWrapper}>
        <Button {...atom.historyButton} />
        <Button
          {...(!allOpen ? { ...atom.expandButton } : { ...atom.foldButton })}
        />
      </div>
    </div>
  );
}
