import MemoryIcon from "@mui/icons-material/Memory";
import { colorPallete } from "../../../../config";

export function ActionMCU() {
  return <MemoryIcon style={{ color: "white" }} />;
}

export function DisableMCU() {
  return <MemoryIcon style={{ color: colorPallete.gray }} />;
}
