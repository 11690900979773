import dayjs from "dayjs";
import "dayjs/locale/ko";
import { colorPallete, titleStyle } from "../../config";
import { IMCU } from "./interface";

const buttonStyleProps = {
  buttonDelete: { backgroundColor: colorPallete.red, border: "none" },
  buttonUpdate: { backgroundColor: colorPallete.orange, border: "none" },
  buttonCheck: { backgroundColor: colorPallete.green, border: "none" },
  buttonCancle: { backgroundColor: colorPallete.gray, border: "none" },
  buttonAdd: { marginBottom: 16 },
};

const styleProps = {
  templateContainer: {
    width: "85%",
    margin: "0px auto",
    height: "100vh",
    overflowY: "auto" as "auto",
  },
  alert: {
    marginTop: "0.9375rem",
    marginBottom: "-1.25rem",
  },
  errorMsg: {
    margin: "0 auto",
  },
  loading: {
    margin: "0 auto",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    height: "80%",
    alignItems: "center",
  },
};

export const deviceProps = {
  template: {
    container: { style: styleProps.templateContainer },
    molecule: {
      atom: {
        title: {
          style: titleStyle,
          children: "MCU",
        },
        alert: {
          style: styleProps.alert,
          type: "error" as "error",
        },
        errorMsg: {
          style: styleProps.errorMsg,
        },
        addButton: {
          style: buttonStyleProps.buttonAdd,
          type: "primary" as "primary",
          children: "Add a MCU",
        },
        loading: {
          wrapper: {
            style: styleProps.loading,
          },
          indicator: { size: "large" as "large" },
        },
      },
    },
  },
};

export const initialRow = {
  key: "",
  state: false,
  wifi_state: false,
  bluetooth_state: false,
  warehousing_date: dayjs(new Date()).format("YYYY-MM-DD"),
  serial_number: "-",
  fk_serial_number: null,
  name: "-",
};

export const newData: IMCU = {
  key: "",
  state: false,
  wifi_state: false,
  bluetooth_state: false,
  warehousing_date: dayjs(new Date()).format("YYYY-MM-DD"),
  serial_number: "-",
  board: {
    serial_number: null,
  },
  name: "-",
  path: "mcu",
};

export const searchParams = [
  { value: "&fk_serial_number=", children: "Board 시리얼 번호" },
  { value: "&name=", children: "제품명" },
  { value: "&serial_number=", children: "시리얼 번호" },
  { value: "&state=", children: "제품 상태" },
  { value: "&wifi_state=", children: "Wifi 사용 여부" },
  { value: "&bluetooth_state=", children: "bBluetooth 사용 여부" },
];
