import { MutateOptions } from "@tanstack/react-query";
import { Tooltip } from "antd";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import utc from "dayjs/plugin/utc";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import Button from "../../atom/Button";

dayjs.extend(utc);
// dayjs.locale("ko");

interface IHistory {
  res: {
    create_date: string;
    link: string;
    writer: string;
    content: string;
  };
  deleteHistory: {
    popconfirm: {
      title: string;
      onConfirm: () => void;
    };
    tooltip: {
      title: string;
      placement: "bottom";
    };
    iconButton: {
      style: {
        border: string;
        color: string;
      };
      type: "text";
      children: string;
    };
  };
  historyItem: {
    wrapper: { style: CSSProperties };
    writer: { style: CSSProperties };
    date: { style: CSSProperties };
    tooltip: { placement: "bottom" };
    link: {
      style: CSSProperties;
      children: string;
      target: string;
    };
  };
  deleteNoteData: (
    variables: any,
    options?: MutateOptions<AxiosResponse<any, any>, any, any, any> | undefined
  ) => void;
}

// 링크 메타데이터 사용 참고
// https://medium.com/@ehdrbdndns/react-react%EC%97%90%EC%84%9C-link-preview-%EA%B5%AC%ED%98%84%ED%95%98%EA%B8%B0-cdfb31f154fe

export default function History({
  res,
  deleteHistory,
  historyItem,
  deleteNoteData,
}: IHistory) {
  return (
    <div key={res.create_date}>
      <p {...historyItem.wrapper}>
        <span {...historyItem.writer}>{res.writer}</span>

        <span {...historyItem.date}>
          {dayjs.utc(res.create_date).local().format("YYYY-MM-DD HH:mm:ss")}
        </span>
      </p>

      <p>{res.content}</p>

      <Tooltip title={res.link} {...historyItem.tooltip}>
        <Link {...historyItem.link} to={res.link} />
      </Tooltip>

      <Button
        {...deleteHistory}
        onConfirm={() => deleteNoteData(res.create_date)}
      />
    </div>
  );
}
