import LanIcon from "@mui/icons-material/Lan";
import { colorPallete } from "../../../../config";

export function ActionEthernet() {
  return <LanIcon style={{ color: "white" }} />;
}

export function DisableEthernet() {
  return <LanIcon style={{ color: colorPallete.gray }} />;
}
