import dayjs from "dayjs";
import "dayjs/locale/ko";
import { colorPallete, titleStyle } from "../../config";
import { IAddDevice } from "./interface";

const buttonStyleProps = {
  buttonDelete: { backgroundColor: colorPallete.red, border: "none" },
  buttonUpdate: { backgroundColor: colorPallete.orange, border: "none" },
  buttonCheck: { backgroundColor: colorPallete.green, border: "none" },
  buttonCancle: { backgroundColor: colorPallete.gray, border: "none" },
  buttonAdd: { marginBottom: 16 },
};

const styleProps = {
  templateContainer: {
    width: "85%",
    margin: "0px auto",
    height: "100vh",
    overflowY: "auto" as "auto",
  },
  alert: {
    marginTop: "0.9375rem",
    marginBottom: "-1.25rem",
  },
  errorMsg: {
    margin: "0 auto",
  },
  loading: {
    margin: "0 auto",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    height: "80%",
    alignItems: "center",
  },
};

export const deviceProps = {
  template: {
    container: { style: styleProps.templateContainer },
    molecule: {
      map: {
        zoom: 10,
        attribution:
          '&copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        tileUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        // tileUrl: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
      },
      atom: {
        title: {
          style: titleStyle,
          children: "Device",
        },
        alert: {
          style: styleProps.alert,
          type: "error" as "error",
        },
        errorMsg: {
          style: styleProps.errorMsg,
        },
        addButton: {
          style: buttonStyleProps.buttonAdd,
          type: "primary" as "primary",
          children: "Add a Device",
        },
        loading: {
          wrapper: {
            style: styleProps.loading,
          },
          indicator: { size: "large" as "large" },
        },
      },
    },
  },
};

export const initialRow = {
  key: "",
  type: "buoy",
  serial_number: "-",
  product_status: "ordered",
  expected_delivery_date: dayjs(new Date()).format("YYYY-MM-DD"),
  shipping_date: dayjs(new Date()).format("YYYY-MM-DD"),
  orderer: "-",
  code_quantity: 0,
  name: "-",
  phone_number: "-",
  emplacement: "-",
};

export const newData: IAddDevice = {
  key: "",
  type: "buoy",
  serial_number: "-",
  product_status: "ordered",
  expected_delivery_date: dayjs(new Date()).format("YYYY-MM-DD"),
  shipping_date: dayjs(new Date()).format("YYYY-MM-DD"),
  orderer: "-",
  code_quantity: 0,
  management: {
    name: "-",
    phone_number: "-",
  },
  path: "device",
  emplacement: "-",
};

export const searchParams = [
  { value: "&type=", children: "타입" },
  { value: "&serial_number=", children: "시리얼 번호" },
  { value: "&product_status=", children: "제품 상태" },
  { value: "&orderer=", children: "수주처" },
  { value: "&operating_status=", children: "작동 상태" },
];
