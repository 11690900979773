import { Button, Result } from "antd";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

interface IErrorPage {
  error: any;
}

const Styles = {
  ErrorPage: styled(Result)`
    margin: 0 auto;
  `,
};
export default function ErrorPage({ error }: IErrorPage) {
  const location = useLocation();
  console.log(error);

  if (error.code === "ERR_NETWORK") {
    return (
      <Styles.ErrorPage
        status="500"
        title={`${error.message}`}
        subTitle={`${error.code}`}
        extra={
          <Button type="primary" key="error">
            <a href={`${location.pathname}`} children={"Go Back"} />
          </Button>
        }
      />
    );
  }

  return (
    <Styles.ErrorPage
      status="404"
      title={`${error.name}`}
      subTitle={`${error.message}`}
      extra={
        <Button type="primary" key="error">
          <a href={`${location.pathname}`} children={"Go Back"} />
        </Button>
      }
    />
  );
}
