import { Alert } from "antd";
import React, { CSSProperties } from "react";

interface IErrorAlertAtom {
  alert?: { style: CSSProperties; type: "error" };
  errorMessage?: string;
  setErrorMessage?: any;
}

export default function ErrorAlert({
  alert,
  errorMessage,
  setErrorMessage,
}: IErrorAlertAtom) {
  return (
    <React.Fragment>
      <Alert
        {...alert}
        message={errorMessage}
        showIcon
        banner
        closable
        onClose={() => setErrorMessage("")}
      />
    </React.Fragment>
  );
}
