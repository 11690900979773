import { Badge } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { Link } from "react-router-dom";
import { TableButtons } from "../../components/molecule";
import { IColumns } from "../../config/interfaces";
import { handleDelete } from "../../util/tableUtil";
import { IDevice } from "./interface";

export default function Columns({
  isEditing,
  save,
  cancel,
  editingKey,
  edit,
  deleteData,
  rows,
  path,
  setModalOpen,
}: IColumns) {
  return [
    {
      title: "ID",
      dataIndex: "key",
      key: "key",
      width: "5%",
      editable: false,
      render: (key: number) => (
        <Link
          to={`/device/${key}`}
          children={key}
          style={{ textDecoration: "underline" }}
        />
      ),
      sorter: {
        compare: (a: IDevice, b: IDevice) => Number(a.key) - Number(b.key),
      },
    },
    {
      title: "타입",
      dataIndex: "type",
      key: "type",
      width: "7%",
      editable: true,
      // filterSearch: false,
      // filters: [
      //   {
      //     text: "buoy",
      //     value: "buoy",
      //   },
      //   {
      //     text: "portable",
      //     value: "portable",
      //   },
      // ],
      // onFilter: (value: string, record: IDevice) =>
      //   record.type?.startsWith(value),
    },
    {
      title: "시리얼 번호",
      dataIndex: "serial_number",
      key: "serial_number",
      editable: true,
      // filterSearch: false,
      // filters: rows?.map((res) => {
      //   return { text: res.serial_number, value: res.serial_number };
      // }),
      // onFilter: (value: string, record: IDevice) =>
      //   record.serial_number?.startsWith(value),
      // render: (_: any, record: IDevice) =>
      //   record.serial_number.split("\t").join(""),
    },
    {
      title: "제품 상태",
      dataIndex: "product_status",
      key: "product_status",
      width: "8%",
      editable: true,
      // filterSearch: false,
      // filters: [
      //   {
      //     text: "ordered",
      //     value: "ordered",
      //   },
      //   {
      //     text: "developing",
      //     value: "developing",
      //   },
      //   {
      //     text: "completed",
      //     value: "completed",
      //   },
      //   {
      //     text: "shipped",
      //     value: "shipped",
      //   },
      // ],
      // onFilter: (value: string, record: IDevice) =>
      //   record.product_status?.startsWith(value),
    },
    {
      title: "납기 예정일",
      dataIndex: "expected_delivery_date",
      key: "expected_delivery_date",
      editable: true,
      render: (_: any, record: IDevice) => {
        const localeDate = dayjs(record.expected_delivery_date).locale("ko");
        const formattingDate = localeDate.format("YYYY-MM-DD");

        return `${formattingDate}`;
      },
    },
    {
      title: "출고일",
      dataIndex: "shipping_date",
      key: "shipping_date",
      editable: true,
      render: (_: any, record: IDevice) => {
        const localeDate = dayjs(record.shipping_date).locale("ko");
        const formattingDate = localeDate.format("YYYY-MM-DD");

        return `${formattingDate}`;
      },
    },
    {
      title: "작동 상태",
      dataIndex: "operating_status",
      key: "operating_status",
      editable: false,
      // filterSearch: false,
      // filters: [
      //   {
      //     text: "ON",
      //     value: "true",
      //   },
      //   {
      //     text: "OFF",
      //     value: "false",
      //   },
      // ],
      // onFilter: (value: string, record: IDevice) =>
      //   record.operating_status?.toString().startsWith(value),
      render: (_: any, record: IDevice) => (
        <Badge
          size="default"
          status={record.operating_status ? "success" : "error"}
          text={record.operating_status ? "ON" : "OFF"}
        />
      ),
    },
    {
      title: "수주처",
      dataIndex: "orderer",
      key: "orderer",
      width: "6%",
      editable: true,
    },
    {
      title: "코드 발급수",
      dataIndex: "code_quantity",
      key: "code_quantity",
      width: "8%",
      editable: true,
      render: (_: any, record: IDevice) => record.code_quantity,
    },
    {
      title: "위치",
      dataIndex: "emplacement",
      key: "emplacement",
      editable: false,
      render: (_: any, record: IDevice) => (
        <Link
          to={""}
          children="Map View"
          onClick={() => setModalOpen && setModalOpen(true)}
          style={{ textDecoration: "underline" }}
          state={{
            type: record?.type,
            device: record?.serial_number,
          }}
        />
      ),
    },
    {
      title: "책임자",
      dataIndex: "management",
      key: "management",
      editable: true,
      children: [
        {
          title: "이름 (상호명)",
          dataIndex: "name",
          key: "name",
          width: "7%",
          editable: true,
          render: (_: any, record: IDevice) => record.management?.name,
        },
        {
          title: "연락처",
          dataIndex: "phone_number",
          key: "phone_number",
          width: "8%",
          editable: true,
          render: (_: any, record: IDevice) => record.management?.phone_number,
        },
      ],
    },
    {
      title: "편집",
      dataIndex: "Action",
      width: "6%",
      align: "center" as const,
      render: (_: any, record: IDevice) => (
        <TableButtons
          isEditing={isEditing}
          record={record}
          editingKey={editingKey}
          path={path}
          deleteData={deleteData}
          edit={edit}
          save={save}
          cancel={cancel}
          handleDelete={handleDelete}
        />
      ),
    },
  ];
}
