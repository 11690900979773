import { colorPallete } from "../../config";
import { ActionManual, OdnLogoBorder } from "../../config/image";
import { ActionDashboard } from "../../static/images/Icon/Sidebar/Dashboard";

const sidebarStyleProps = {
  templateContainer: {
    width: "13%",
    height: "100vh",
    backgroundColor: colorPallete.side,
    color: "white",
  },
  anchorStyleProps: {
    imageStyle: {
      width: "25%",
    },
    titleSectionStyle: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      backgroundColor: colorPallete.side,
      width: "100%",
      color: colorPallete.white,
    },
    titleFontStyle: {
      fontSize: "1.25rem",
    },
    ulStyle: {
      listStyleType: "none",
      textDecoration: "none",
      // marginLeft: "1.5rem",
    },
    subUlStyle: {
      listStyleType: "none",
      textDecoration: "none",
      // marginLeft: "-0.9375rem",
    },
    liStyle: {
      textDecoration: "none",
      lineHeight: "3.125rem",
    },
  },
};

export const sidebarProps = {
  props: {
    container: sidebarStyleProps.templateContainer,
    anchor: {
      titleProps: {
        section: sidebarStyleProps.anchorStyleProps.titleSectionStyle,
        atom: {
          title: {
            children: "Device Admin",
            style: sidebarStyleProps.anchorStyleProps.titleFontStyle,
          },
          image: {
            style: sidebarStyleProps.anchorStyleProps.imageStyle,
            src: OdnLogoBorder,
            alt: "logo",
          },
        },
      },
      ulProps: sidebarStyleProps.anchorStyleProps.ulStyle,
      path: {
        manualPath: {
          sx: { pl: 2 },
          target: "_blank",
          key: "Manual",
          pageName: "Manual",
          pathname: "Manual",
          to: "https://www.notion.so/hyungjinhan/Device-Admin-43251addd5ab431995320bf18319e1df?pvs=4",
          actionIcon: ActionManual,
          disableIcon: ActionManual,
          style: { textDecoration: "underline" },
        },
        dashboardPath: {
          sx: { pl: 2 },
          target: "_blank",
          key: "Dashboard",
          pageName: "Dashboard",
          pathname: "Dashboard",
          to: "https://grafana.device.odn.us/",
          actionIcon: ActionDashboard,
          disableIcon: ActionDashboard,
          style: { textDecoration: "underline" },
        },
        mainPath: {
          sx: { pl: 2 },
        },
        subPath: {
          sx: { pl: 6 },
        },
        childPath: {
          sx: { pl: 10 },
        },
      },
    },
  },
};
