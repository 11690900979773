import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { baseURL } from "../util/axiosUtil";

interface IDeleteConfig {
  path: string;
  serial_number: string;
}

const addNoteData = async (data: any) => {
  return await axios({
    baseURL: `${baseURL}`,
    url: `/note`,
    method: "post",
    data: data,
  });
};

const addBuoyData = async (newData: any) => {
  const { path, key, ...data } = newData;

  return await axios({
    baseURL: `${baseURL}`,
    url: `/${path}`,
    method: "post",
    data: data,
  });
};

const updateBuoyData = async (newData: any) => {
  const { path, old_serial_number, ...data } = newData;

  return await axios({
    baseURL: `${baseURL}`,
    url: `/${path}/update/${old_serial_number}`,
    method: "put",
    data: data,
  });
};

const deleteBuoyData = async (deleteConfig: IDeleteConfig) => {
  return await axios({
    baseURL: `${baseURL}`,
    url: `/${deleteConfig.path}/delete/${deleteConfig.serial_number}`,
    method: "delete",
  });
};

const deleteNoteData = async (date: any) => {
  return await axios({
    baseURL: `${baseURL}`,
    url: `/note/delete/${date}`,
    method: "delete",
  });
};

export const useAddData = (
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  queryKey: string
) => {
  const queryClientInfo = useQueryClient();

  return useMutation({
    mutationFn: addBuoyData,
    onMutate: async (newData: any) => {
      // Optimistic Update(낙관적 업데이트)를 덮어쓰지 않기 위해 쿼리를 수동으로 삭제

      // 이전 값
      const prevData = queryClientInfo.getQueryData([`${queryKey}`]);

      // 새로운 값으로 Optimistic Update(낙관적 업데이트) 진행
      // queryClientInfo.setQueryData(["buoy"], (oldData: IDevice) => {
      //   return {
      //     ...oldData,
      //     data: [...oldData, { ...newData }],
      //   };
      // });

      return {
        // 값이 들어있는 context 객체를 반환
        prevData,
      };
    },

    // mutation 실패 시, onMutate에서 반환된 context를 사용하여 롤백 진행
    onError: (_error: any, _data, context: any) => {
      if (_error.response) {
        setErrorMessage(
          _error.response?.data.detail[0].msg
            ? _error.response?.data.detail[0].msg
            : _error.response?.data.detail
        );
      } else {
        setErrorMessage(_error.message);
      }
      queryClientInfo.setQueryData([`${queryKey}`], context.prevData);
    },

    // 오류 또는 성공 후, 항상 데이터 리프레쉬 진행
    onSettled: () => {
      // console.log({ onSettled: "Refresh" });
      queryClientInfo.invalidateQueries({ queryKey: [`${queryKey}`] });
    },
  });
};

export const useUpdateData = (
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  queryKey: string
) => {
  const queryClientInfo = useQueryClient();

  return useMutation({
    mutationFn: updateBuoyData,
    onSuccess: () => {
      // console.log({ updateValue: { id: id, data: data } });
      queryClientInfo.invalidateQueries({ queryKey: [`${queryKey}`] });
      // post, delete 시, 실시간으로 최신화 시켜주는 작업
      // 키가 여러 개라면, ["heroes", "detail", ...]
    },
    onError: (_error: any, _data, context: any) => {
      if (_error.response) {
        setErrorMessage(
          _error.response?.data.detail[0].msg
            ? _error.response?.data.detail[0].msg
            : _error.response?.data.detail
        );
      } else {
        setErrorMessage(_error.message);
      }
      queryClientInfo.invalidateQueries({ queryKey: [`${queryKey}`] });
    },
  });
};

export const useDeleteData = (
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  queryKey: string
) => {
  const queryClientInfo = useQueryClient();

  return useMutation({
    mutationFn: deleteBuoyData,
    onError: (_error: any, _data, context: any) => {
      if (_error.response) {
        setErrorMessage(
          _error.response?.data.detail[0].msg
            ? _error.response?.data.detail[0].msg
            : _error.response?.data.detail
        );
      } else {
        setErrorMessage(_error.message);
      }
      queryClientInfo.invalidateQueries({ queryKey: [`${queryKey}`] });
    },
    onSuccess: () => {
      queryClientInfo.invalidateQueries({ queryKey: [`${queryKey}`] });
      // post, delete 시, 실시간으로 최신화 시켜주는 작업
      // 키가 여러 개라면, ["heroes", "detail", ...]
    },
  });
};

export const useAddNote = (
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  queryKey: string
) => {
  const queryClientInfo = useQueryClient();

  return useMutation({
    mutationFn: addNoteData,
    onMutate: async (newData: any) => {
      const prevData = queryClientInfo.getQueryData([`${queryKey}`]);
      return {
        prevData,
      };
    },

    onError: (_error: any, _data, context: any) => {
      if (_error.response) {
        setErrorMessage(
          _error.response?.data.detail[0].msg
            ? _error.response?.data.detail[0].msg
            : _error.response?.data.detail
        );
      } else {
        setErrorMessage(_error.message);
      }
      queryClientInfo.setQueryData([`${queryKey}`], context.prevData);
    },

    // 오류 또는 성공 후, 항상 데이터 리프레쉬 진행
    onSettled: () => {
      queryClientInfo.invalidateQueries({ queryKey: [`${queryKey}`] });
    },
  });
};

export const useDeleteNote = (
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  queryKey: string
) => {
  const queryClientInfo = useQueryClient();

  return useMutation({
    mutationFn: deleteNoteData,
    onError: (_error: any, _data, context: any) => {
      if (_error.response) {
        setErrorMessage(
          _error.response?.data.detail[0].msg
            ? _error.response?.data.detail[0].msg
            : _error.response?.data.detail
        );
      } else {
        setErrorMessage(_error.message);
      }
      queryClientInfo.invalidateQueries({ queryKey: [`${queryKey}`] });
    },
    onSuccess: () => {
      queryClientInfo.invalidateQueries({ queryKey: [`${queryKey}`] });
      // post, delete 시, 실시간으로 최신화 시켜주는 작업
      // 키가 여러 개라면, ["heroes", "detail", ...]
    },
  });
};
