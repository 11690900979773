import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import { colorPallete } from "../../../../config";

export function ActionMppt() {
  return <ElectricMeterIcon style={{ color: "white" }} />;
}

export function DisableMppt() {
  return <ElectricMeterIcon style={{ color: colorPallete.gray }} />;
}
