import { List, ListSubheader } from "@mui/material";
import styled from "styled-components";
import {
  IContainerStyle,
  ITemplateProps,
  ITitleSection,
} from "../../../page/Sidebar/interface";
import { Image, Title } from "../../atom";
import { Anchors } from "../../molecule";

const Styles = {
  Container: styled.div<IContainerStyle>`
    ${(props) => props.style}
  `,
  TitleSection: styled.div<ITitleSection>`
    ${(props) => props.style}
  `,
};

export default function SidebarTemplate({
  pathname,
  path,
  subPath,
  props,
}: ITemplateProps) {
  return (
    <List
      style={props.container}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={props.anchor.titleProps.section}>
          <Styles.TitleSection style={props.anchor.titleProps.section}>
            <Image {...props.anchor.titleProps.atom.image} />
            <Title {...props.anchor.titleProps.atom} />
          </Styles.TitleSection>
        </ListSubheader>
      }>
      <Anchors pathname={pathname} path={path} subPath={subPath} {...props} />
    </List>
  );
}
