import { Button as AntdButton, Popconfirm, Tooltip } from "antd";
import { ITooltip } from "../../../config/interfaces";

export default function Button({
  popconfirm,
  tooltip,
  iconButton,
  onClick,
  onConfirm,
}: ITooltip) {
  return popconfirm ? (
    <Popconfirm
      title={popconfirm.title}
      onConfirm={onConfirm ? onConfirm : popconfirm.onConfirm}>
      <Tooltip title={tooltip?.title} placement={tooltip?.placement}>
        <AntdButton
          style={iconButton.style}
          icon={iconButton.icon}
          disabled={iconButton.disabled}
          type={iconButton.type}
          children={iconButton.children}
        />
      </Tooltip>
    </Popconfirm>
  ) : (
    <Tooltip title={tooltip?.title} placement={tooltip?.placement}>
      <AntdButton
        style={iconButton.style}
        icon={iconButton.icon}
        disabled={iconButton.disabled}
        type={iconButton.type}
        children={iconButton.children}
        onClick={onClick ? onClick : iconButton.onClick}
      />
    </Tooltip>
  );
}

// iconButton: {
//     style: React.CSSProperties;
//     icon?: React.ReactNode;
//     onClick?: (() => void) | undefined;
//     disabled?: boolean | undefined;
//     type?: "default" | "primary" | "dashed" | "link" | "text" | undefined;
//     children?: string | undefined;
// }
