import { CSSProperties } from "styled-components";
import { ErrorAlert, Title } from "../../atom";

interface ITitleMolecule {
  atom: {
    alert: { style: CSSProperties; type: "error" };
    title: {
      style?: CSSProperties;
      children: string;
    };
  };
  errorMessage?: string;
  setErrorMessage?: any;
}

export default function TitleSection({
  atom,
  errorMessage,
  setErrorMessage,
}: ITitleMolecule) {
  return (
    <div>
      {errorMessage && (
        <ErrorAlert
          {...atom}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      <Title {...atom} />
    </div>
  );
}
