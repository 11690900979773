import { Button, Form } from "antd";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { ISearchParams, ITable } from "../../../config/interfaces";
import { ErrorPage, Loading } from "../../atom";
import SearchBar from "../SeachBar";

const Styles = {
  SearchContainer: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.9375rem;
  `,
};

interface ITableMolecule extends ITable {
  atom: {
    addButton: {
      style: CSSProperties;
      type: "primary";
      children: "string";
    };
    loading: {
      wrapper: { style: CSSProperties };
      indicator: { size: "large" };
    };
  };
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setAddMode: React.Dispatch<React.SetStateAction<boolean>>;
  searchFilter?: string;
  setSearchFilter: React.Dispatch<React.SetStateAction<string>>;
  searchParams: ISearchParams[];
  setSearchResult: React.Dispatch<React.SetStateAction<string>>;
}

export default function TableSection({
  atom,
  setErrorMessage,
  handleAdd,
  form,
  initialRow,
  newData,
  addData,
  editingKey,
  addMode,
  tableChildren,
  fetching,
  status,
  error,
  setAddMode,
  searchFilter,
  setSearchFilter,
  searchParams,
  setSearchResult,
}: ITableMolecule) {
  if (fetching) {
    return <Loading {...atom} />;
  }

  if (status === "error") {
    // status -> success, loading, error...
    return <ErrorPage error={error} />;
  }

  return (
    <div>
      <Styles.SearchContainer>
        <Button
          onClick={() =>
            handleAdd({
              form,
              initialRow,
              newData,
              setErrorMessage,
              addData,
              setAddMode,
            })
          }
          disabled={editingKey !== "" || addMode}
          {...atom.addButton}
          style={{ display: "flex" }}
        />

        <SearchBar
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          setSearchResult={setSearchResult}
          searchParams={searchParams}
        />
      </Styles.SearchContainer>

      <Form form={form} component={false} children={tableChildren} />
    </div>
  );
}
