import { Badge } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { Link } from "react-router-dom";
import { IDeviceDetail } from "./interface";

export default function Columns(
  key: string,
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
) {
  const dateFormatter = (date: string | Date) => {
    const localeDate = dayjs(date).locale("ko");
    const formattingDate = localeDate.format("YYYY-MM-DD");

    if (date) {
      return `${formattingDate}`;
    } else {
      return "";
    }
  };

  const badgeRenderer = (status: boolean | null | undefined) => {
    if (status !== null && status !== undefined) {
      return (
        <Badge
          size="default"
          status={status ? "success" : "error"}
          text={status ? "ON" : "OFF"}
        />
      );
    } else {
      return "";
    }
  };

  if (key === "Device") {
    return [
      {
        title: "타입",
        dataIndex: "type",
        key: "type",
        width: "7%",
      },
      {
        title: "시리얼 번호",
        dataIndex: "serial_number",
        key: "serial_number",
      },
      {
        title: "제품 상태",
        dataIndex: "product_status",
        key: "product_status",
        width: "8%",
      },
      {
        title: "납기 예정일",
        dataIndex: "expected_delivery_date",
        key: "expected_delivery_date",
        render: (_: any, record: IDeviceDetail["device"]) => {
          return dateFormatter(record?.expected_delivery_date);
        },
      },
      {
        title: "출고일",
        dataIndex: "shipping_date",
        key: "shipping_date",
        render: (_: any, record: IDeviceDetail["device"]) => {
          return dateFormatter(record?.shipping_date);
        },
      },
      {
        title: "작동 상태",
        dataIndex: "operating_status",
        key: "operating_status",
        render: (_: any, record: IDeviceDetail["device"]) => {
          return badgeRenderer(record?.operating_status);
        },
      },
      {
        title: "수주처",
        dataIndex: "orderer",
        key: "orderer",
        width: "6%",
      },
      {
        title: "코드 발급수",
        dataIndex: "code_quantity",
        key: "code_quantity",
        width: "8%",
        render: (_: any, record: IDeviceDetail["device"]) =>
          record?.code_quantity,
      },
      {
        title: "위치",
        dataIndex: "emplacement",
        key: "emplacement",
        render: (_: any, record: IDeviceDetail["device"]) => (
          <Link
            to={""}
            children="Map View"
            onClick={() => setModalOpen && setModalOpen(true)}
            style={{ textDecoration: "underline" }}
            state={{
              type: record?.type,
              device: record?.serial_number,
            }}
          />
        ),
      },
      {
        title: "책임자",
        dataIndex: "management",
        key: "management",
        children: [
          {
            title: "이름 (상호명)",
            dataIndex: "name",
            key: "name",
            width: "7%",
            render: (_: any, record: IDeviceDetail["device"]) =>
              record?.management?.name,
          },
          {
            title: "연락처",
            dataIndex: "phone_number",
            key: "phone_number",
            width: "8%",
            render: (_: any, record: IDeviceDetail["device"]) =>
              record?.management?.phone_number,
          },
        ],
      },
    ];
  } else if (key === "MPPT") {
    return [
      {
        title: "시리얼 번호",
        dataIndex: "serial_number",
        key: "serial_number",
      },
      {
        title: "입고일",
        dataIndex: "warehousing_date",
        key: "warehousing_date",
        render: (_: any, record: IDeviceDetail["mppt"]) => {
          return dateFormatter(record?.warehousing_date);
        },
      },
      {
        title: "제품 상태",
        dataIndex: "state",
        key: "state",
        render: (_: any, record: IDeviceDetail["mppt"]) => {
          return badgeRenderer(record?.state);
        },
      },
      {
        title: "전력",
        dataIndex: "volume",
        key: "volume",
        children: [
          {
            title: "전압",
            dataIndex: "voltage",
            key: "voltage",
            render: (_: any, record: IDeviceDetail["mppt"]) =>
              record?.volume?.voltage,
          },
          {
            title: "전류",
            dataIndex: "electric_current",
            key: "electric_current",
            render: (_: any, record: IDeviceDetail["mppt"]) =>
              record?.volume?.electric_current,
          },
        ],
      },
    ];
  } else if (key === "Battery") {
    return [
      {
        title: "제품명",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "warehousing_date",
        dataIndex: "warehousing_date",
        key: "warehousing_date",
        render: (_: any, record: IDeviceDetail["battery"]) => {
          return dateFormatter(record?.warehousing_date);
        },
      },
      {
        title: "제품 상태",
        dataIndex: "state",
        key: "state",
        render: (_: any, record: IDeviceDetail["battery"]) => {
          return badgeRenderer(record?.state);
        },
      },
      {
        title: "전력",
        dataIndex: "volume",
        key: "volume",
        children: [
          {
            title: "전압",
            dataIndex: "voltage",
            key: "voltage",
            render: (_: any, record: IDeviceDetail["battery"]) =>
              record?.volume?.voltage,
          },
          {
            title: "전류",
            dataIndex: "electric_current",
            key: "electric_current",
            render: (_: any, record: IDeviceDetail["battery"]) =>
              record?.volume?.electric_current,
          },
        ],
      },
    ];
  } else if (key === "Solar Panel") {
    return [
      {
        title: "시리얼 번호",
        dataIndex: "serial_number",
        key: "serial_number",
      },
      {
        title: "입고일",
        dataIndex: "warehousing_date",
        key: "warehousing_date",
        render: (_: any, record: IDeviceDetail["solarPanel"]) => {
          return dateFormatter(record?.warehousing_date);
        },
      },
      {
        title: "제품 상태",
        dataIndex: "state",
        key: "state",
        render: (_: any, record: IDeviceDetail["solarPanel"]) => {
          return badgeRenderer(record?.state);
        },
      },
      {
        title: "전력",
        dataIndex: "volume",
        key: "volume",
        editable: true,
        children: [
          {
            title: "전압",
            dataIndex: "voltage",
            key: "voltage",
            editable: true,
            render: (_: any, record: IDeviceDetail["solarPanel"]) =>
              record?.volume?.voltage,
          },
          {
            title: "전류",
            dataIndex: "electric_current",
            key: "electric_current",
            editable: true,
            render: (_: any, record: IDeviceDetail["solarPanel"]) =>
              record?.volume?.electric_current,
          },
        ],
      },
    ];
  } else if (key === "Board") {
    return [
      {
        title: "타입",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "시리얼 번호",
        dataIndex: "serial_number",
        key: "serial_number",
      },
      {
        title: "입고일",
        dataIndex: "warehousing_date",
        key: "warehousing_date",
        render: (_: any, record: IDeviceDetail["board"]) => {
          return dateFormatter(record?.warehousing_date);
        },
      },
      {
        title: "제조사",
        dataIndex: "manufacturer",
        key: "manufacturer",
      },
      {
        title: "제품 상태",
        dataIndex: "state",
        key: "state",
        render: (_: any, record: IDeviceDetail["board"]) => {
          return badgeRenderer(record?.state);
        },
      },
    ];
  } else if (key === "MCU") {
    return [
      {
        title: "시리얼 번호",
        dataIndex: "serial_number",
        key: "serial_number",
      },
      {
        title: "제품 상태",
        dataIndex: "state",
        key: "state",
        render: (_: any, record: IDeviceDetail["mcu"]) => {
          return badgeRenderer(record?.state);
        },
      },
      {
        title: "Wifi 사용 여부",
        dataIndex: "wifi_state",
        key: "wifi_state",
        render: (_: any, record: IDeviceDetail["mcu"]) => {
          return badgeRenderer(record?.wifi_state);
        },
      },
      {
        title: "Bluetooth 사용 여부",
        dataIndex: "bluetooth_state",
        key: "bluetooth_state",
        render: (_: any, record: IDeviceDetail["mcu"]) => {
          return badgeRenderer(record?.bluetooth_state);
        },
      },
      {
        title: "입고일",
        dataIndex: "warehousing_date",
        key: "warehousing_date",
        render: (_: any, record: IDeviceDetail["mcu"]) => {
          return dateFormatter(record?.warehousing_date);
        },
      },
      {
        title: "제품명",
        dataIndex: "name",
        key: "name",
      },
    ];
  } else if (key === "Ethernet") {
    return [
      {
        title: "시리얼 번호",
        dataIndex: "serial_number",
        key: "serial_number",
      },
      {
        title: "제품 상태",
        dataIndex: "state",
        key: "state",
        render: (_: any, record: IDeviceDetail["ethernet"]) => {
          return badgeRenderer(record?.state);
        },
      },
      {
        title: "입고일",
        dataIndex: "warehousing_date",
        key: "warehousing_date",
        render: (_: any, record: IDeviceDetail["ethernet"]) => {
          return dateFormatter(record?.warehousing_date);
        },
      },
      {
        title: "제품명",
        dataIndex: "name",
        key: "name",
      },
    ];
  } else if (key === "Modem") {
    return [
      {
        title: "시리얼 번호",
        dataIndex: "serial_number",
        key: "serial_number",
      },
      {
        title: "제품 상태",
        dataIndex: "state",
        key: "state",
        render: (_: any, record: IDeviceDetail["modem"]) => {
          return badgeRenderer(record?.state);
        },
      },
      {
        title: "입고일",
        dataIndex: "warehousing_date",
        key: "warehousing_date",
        render: (_: any, record: IDeviceDetail["modem"]) => {
          return dateFormatter(record?.warehousing_date);
        },
      },
      {
        title: "제품명",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "IMEI",
        dataIndex: "imei",
        key: "imei",
      },
    ];
  } else if (key === "Sim") {
    return [
      {
        title: "제품명",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "개통일",
        dataIndex: "activate_date",
        key: "activate_date",
        render: (_: any, record: IDeviceDetail["sim"]) => {
          return dateFormatter(record?.activate_date);
        },
      },
      {
        title: "제품 상태",
        dataIndex: "state",
        key: "state",
        render: (_: any, record: IDeviceDetail["sim"]) => {
          return badgeRenderer(record?.state);
        },
      },
      {
        title: "IMSI",
        dataIndex: "imsi",
        key: "imsi",
      },
    ];
  } else if (key === "Multiparameter") {
    return [
      {
        title: "시리얼 번호",
        dataIndex: "serial_number",
        key: "serial_number",
      },
      {
        title: "입고일",
        dataIndex: "warehousing_date",
        key: "warehousing_date",
        render: (_: any, record: IDeviceDetail["multiparameter"]) => {
          return dateFormatter(record?.warehousing_date);
        },
      },
      {
        title: "제품명",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "제품 상태",
        dataIndex: "state",
        key: "state",
        render: (_: any, record: IDeviceDetail["multiparameter"]) => {
          return badgeRenderer(record?.state);
        },
      },
    ];
  } else if (key === "Sensor") {
    return [
      {
        title: "시리얼 번호",
        dataIndex: "serial_number",
        key: "serial_number",
      },
      {
        title: "보증 기간",
        dataIndex: "warranty",
        key: "warranty",
        render: (_: any, record: IDeviceDetail["sensor"]) => {
          return dateFormatter(record?.warranty);
        },
      },
      {
        title: "제품명",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "제품 상태",
        dataIndex: "state",
        key: "state",
        render: (_: any, record: IDeviceDetail["sensor"]) => {
          return badgeRenderer(record?.state);
        },
      },
    ];
  }
}
