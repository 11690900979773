import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import React from "react";
import styled from "styled-components";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text" | "date" | "select";
  record: any;
  index: number;
  children: React.ReactNode;
  addMode?: boolean;
}

const Styles = {
  FormItem: styled(Form.Item)`
    margin: 0;
  `,
  DatePicker: styled(DatePicker)`
    width: 100%;
  `,
};

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  addMode,
  ...restProps
}) => {
  let inputNode;

  if (inputType === "date") {
    if (dataIndex === "expected_delivery_date") {
      const localeDate = dayjs(record.expected_delivery_date).locale("ko");

      inputNode = <Styles.DatePicker value={localeDate} allowClear={false} />;
    }

    if (dataIndex === "shipping_date") {
      const localeDate = dayjs(record.shipping_date).locale("ko");

      inputNode = <Styles.DatePicker value={localeDate} allowClear={false} />;
    }

    if (dataIndex === "warehousing_date") {
      const localeDate = dayjs(record.warehousing_date).locale("ko");

      inputNode = <Styles.DatePicker value={localeDate} allowClear={false} />;
    }

    if (dataIndex === "warranty") {
      const localeDate = dayjs(record.warehousing_date).locale("ko");

      inputNode = <Styles.DatePicker value={localeDate} allowClear={false} />;
    }
  }

  if (inputType === "number") {
    inputNode = <InputNumber />;
  }

  if (inputType === "text") {
    inputNode = <Input />;
  }

  if (inputType === "select") {
    let options;

    if (dataIndex === "type") {
      options = [
        { value: "buoy", label: "buoy" },
        { value: "portable", label: "portable" },
      ];
    }

    if (
      dataIndex === "operating_status" ||
      dataIndex === "state" ||
      dataIndex === "wifi_state" ||
      dataIndex === "bluetooth_state"
    ) {
      options = [
        { value: true, label: "ON" },
        { value: false, label: "OFF" },
      ];
    }

    if (dataIndex === "product_status") {
      options = [
        { value: "ordered", label: "ordered" },
        { value: "developing", label: "developing" },
        { value: "completed", label: "completed" },
        { value: "shipped", label: "shipped" },
      ];
    }

    inputNode = <Select options={options} />;
  }

  const formRules = (title: string) => {
    if (dataIndex === "phone_number") {
      return [
        {
          required: true,
          message: `Required`,
        },
        {
          whitespace: true,
          message: "No spaces allowed",
        },
        {
          pattern: /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{3,4}$/,
          message: `Check ${title}'s type`,
        },
      ];
    } else if (dataIndex === "fk_serial_number") {
      return [
        {
          whitespace: true,
          message: "No spaces allowed",
        },
      ];
    } else if (dataIndex === "serial_number") {
      return [
        {
          whitespace: true,
          message: "No spaces allowed",
        },
        {
          required: true,
          message: "Required",
        },
        {
          pattern: /[^.]{3}$/,
          message: "at least 3 characters",
        },
      ];
    } else if (
      dataIndex === "electric_current" ||
      dataIndex === "voltage" ||
      dataIndex === "code_quantity"
    ) {
      return [
        {
          required: true,
          message: "Required",
        },
      ];
    } else {
      return [
        {
          required: true,
          message: "Required",
        },
      ];
    }
  };

  const formDataValidate = (value: any, dataIndex: string) => {
    if (dataIndex === "phone_number") {
      return value
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    } else if (
      dataIndex === "type" ||
      dataIndex === "operating_status" ||
      dataIndex === "state" ||
      dataIndex === "wifi_state" ||
      dataIndex === "bluetooth_state" ||
      dataIndex === "product_status"
    ) {
      return value;
    } else {
      return value.trim();
    }
  };

  return (
    <td {...restProps}>
      {editing ? (
        inputType === "number" ||
        inputType === "text" ||
        inputType === "select" ? (
          inputType === "number" ? (
            <Styles.FormItem
              key={index}
              name={dataIndex}
              rules={formRules(title)}>
              {inputNode}
            </Styles.FormItem>
          ) : (
            <Styles.FormItem
              key={index}
              name={dataIndex}
              rules={formRules(title)}
              normalize={(value) => formDataValidate(value, dataIndex)}>
              {inputNode}
            </Styles.FormItem>
          )
        ) : (
          <Styles.FormItem
            key={index}
            name={dataIndex}
            getValueFromEvent={(onChange) =>
              dayjs(onChange).format("YYYY-MM-DD")
            }
            getValueProps={(i) => ({ value: dayjs(i) })}
            rules={formRules(title)}
            normalize={(value) => formDataValidate(value, dataIndex)}>
            {inputNode}
          </Styles.FormItem>
        )
      ) : (
        children
      )}
    </td>
  );
};
