import HomeIcon from "@mui/icons-material/Home";
import { colorPallete } from "../../../../config";

export function ActionHome() {
  return <HomeIcon style={{ color: "white" }} />;
}

export function DisableHome() {
  return <HomeIcon style={{ color: colorPallete.gray }} />;
}
