import { Badge } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { TableButtons } from "../../components/molecule";
import { IColumns } from "../../config/interfaces";
import { handleDelete } from "../../util/tableUtil";
import { ISolarPanel } from "./interface";

export default function Columns({
  isEditing,
  save,
  cancel,
  editingKey,
  edit,
  deleteData,
  rows,
  path,
  addMode,
}: IColumns) {
  return [
    {
      title: "ID",
      dataIndex: "key",
      key: "key",
      width: "5%",
      editable: false,
      sorter: {
        compare: (a: ISolarPanel, b: ISolarPanel) =>
          Number(a.key) - Number(b.key),
      },
    },
    {
      title: "시리얼 번호",
      dataIndex: "serial_number",
      key: "serial_number",
      editable: true,
    },
    {
      title: "입고일",
      dataIndex: "warehousing_date",
      key: "warehousing_date",
      editable: true,
      render: (_: any, record: ISolarPanel) => {
        const localeDate = dayjs(record.warehousing_date).locale("ko");
        const formattingDate = localeDate.format("YYYY-MM-DD");

        return `${formattingDate}`;
      },
    },
    {
      title: "제품 상태",
      dataIndex: "state",
      key: "state",
      editable: true,
      render: (_: any, record: ISolarPanel) => (
        <Badge
          size="default"
          status={record.state ? "success" : "error"}
          text={record.state ? "ON" : "OFF"}
        />
      ),
    },
    {
      title: "전력",
      dataIndex: "volume",
      key: "volume",
      editable: true,
      children: [
        {
          title: "전압",
          dataIndex: "voltage",
          key: "voltage",
          editable: true,
          render: (_: any, record: ISolarPanel) => record.volume?.voltage,
        },
        {
          title: "전류",
          dataIndex: "electric_current",
          key: "electric_current",
          editable: true,
          render: (_: any, record: ISolarPanel) =>
            record.volume?.electric_current,
        },
      ],
    },
    {
      title: "Device 시리얼 번호",
      dataIndex: "fk_serial_number",
      key: "fk_serial_number",
      editable: true,
      addMode: addMode,
      render: (_: any, record: ISolarPanel) => record.device?.serial_number,
    },
    {
      title: "편집",
      dataIndex: "Action",
      width: "6%",
      align: "center" as const,
      render: (_: any, record: ISolarPanel) => (
        <TableButtons
          isEditing={isEditing}
          record={record}
          editingKey={editingKey}
          path={path}
          deleteData={deleteData}
          edit={edit}
          save={save}
          cancel={cancel}
          handleDelete={handleDelete}
        />
      ),
    },
  ];
}
