import { Divider, Table } from "antd";
import { CSSProperties } from "react";
import { IDetail } from "../../../config/interfaces";
import Columns from "../../../page/DeviceDetail/Columns";
import { ErrorPage, Loading, Title } from "../../atom";

interface ITableMolecule extends IDetail {
  tableWrapper: { style: CSSProperties };
  flexWrapper: { style: CSSProperties };
  atom: {
    title: any;
    table: { style: CSSProperties };
    loading: {
      wrapper: { style: CSSProperties };
      indicator: { size: "large" };
    };
  };
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IDataMap {
  info: string;
  data: any;
}

export default function DetailTableSection({
  tableWrapper,
  flexWrapper,
  atom,
  rows,
  dropdown,
  status,
  error,
  fetching,
  handleDropdown,
  setModalOpen,
}: ITableMolecule) {
  if (fetching) {
    return <Loading {...atom} />;
  }

  if (status === "error") {
    // status -> success, loading, error...
    return <ErrorPage error={error} />;
  }

  return (
    <div {...tableWrapper}>
      <div {...flexWrapper}>
        {rows.map((res: IDataMap) => (
          <div
            key={res.info}
            style={{
              width: res.info === "Device" ? "100%" : "33%",
            }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {res.info === "Sensor" ? null : (
                <Divider
                  orientationMargin={10}
                  orientation="left"
                  children={
                    <Title
                      {...atom.title}
                      onClick={() => handleDropdown(res.info)}
                      children={
                        dropdown.includes(res.info)
                          ? `${res.info} -`
                          : `${res.info} +`
                      }
                    />
                  }
                />
              )}

              {res.info === "Sensor" ? null : (
                <Table
                  key={res.info}
                  bordered
                  dataSource={[res.data]}
                  columns={Columns(res.info, setModalOpen)}
                  pagination={false}
                  scroll={{ y: "65vh" }}
                  style={{
                    ...atom.table.style,
                    display: dropdown.includes(res.info) ? "" : "none",
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </div>

      {rows.map((res: IDataMap) => (
        <div key={res.info}>
          {res.info !== "Sensor" ? null : (
            <Divider
              orientationMargin={10}
              orientation="left"
              children={
                <Title
                  {...atom.title}
                  children={
                    dropdown.includes(res.info)
                      ? `${res.info} -`
                      : `${res.info} +`
                  }
                  onClick={() => handleDropdown(res.info)}
                />
              }
            />
          )}

          {res.info === "Sensor" ? (
            <Table
              key={res.info}
              bordered
              dataSource={res.data?.map((res: any) => res)}
              columns={Columns(res.info)}
              pagination={false}
              scroll={{ y: "65vh" }}
              style={{
                ...atom.table.style,
                display: dropdown.includes(res.info) ? "" : "none",
              }}
            />
          ) : null}
        </div>
      ))}
    </div>
  );
}
