import { Outlet, useLocation } from "react-router-dom";
import { SidebarTemplate } from "../../components/template";
import { path, subPath } from "../../path";
import { sidebarProps } from "./data";

export default function Sidebar() {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <>
      <SidebarTemplate
        pathname={pathname}
        path={path}
        subPath={subPath}
        {...sidebarProps}
      />
      <Outlet />
    </>
  );
}
