import { GeoJsonObject } from "geojson";
import L, { LatLngExpression, Layer } from "leaflet";
import { useEffect } from "react";
import { GeoJSON, MapContainer, TileLayer, useMap } from "react-leaflet";
import { useLocation } from "react-router";
import styled from "styled-components";
import { BuoyIcon, SensorIcon } from "../../../config/image";
import { ErrorPage, Loading } from "../../atom";
import "./style.css";

const Styles = {
  MapView: styled(MapContainer)`
    width: 100%;
    height: 50vh;
    margin-top: 30px;
    border-radius: 5px;
  `,
  Loading: styled.div`
    width: 100%;
    height: 50vh;
    margin-bottom: 15px;
    margin-top: 15px;
    display: flex;
    align-items: center;
  `,
};

interface IMapView {
  modalOpen?: boolean;
  map: { zoom: number; attribution: string; tileUrl: string };
  atom: any;
  locationStatus: any;
  locationError: any;
  data: any;
  center: LatLngExpression;
  locationIsFetching: boolean;
}

interface IResizeMap {
  zoom: number;
  center: LatLngExpression;
  modalOpen?: boolean;
}

// 모달 닫을 시, 위치, 줌, 팝업 등 초기화 이벤트
const ResizeMap = ({ center, zoom, modalOpen }: IResizeMap) => {
  const map = useMap();

  useEffect(() => {
    map.invalidateSize();
  }, [map]);

  // 모달 오픈 시, 맵 뷰 초기화 (팝업, 위치)
  if (modalOpen) map.setView(center, zoom);
  if (modalOpen) map.closePopup();

  return null;
};

export default function MapView({
  modalOpen,
  map,
  atom,
  locationStatus,
  locationError,
  data,
  center,
  locationIsFetching,
}: IMapView) {
  const location = useLocation();
  const type = location.state?.type;

  const customIcon = {
    buoyIcon: new L.Icon({
      iconUrl: BuoyIcon,
      iconAnchor: [18, 10],
      popupAnchor: [0, -15],
    }),
    portableIcon: new L.Icon({
      iconUrl: SensorIcon,
      iconAnchor: [18, 10],
      popupAnchor: [0, -15],
    }),
  };

  const handlePopup = (data: any, layer: Layer) => {
    const { geometry } = data;

    // const googleMapSearch = `https://www.google.com/search?q=${geometry.coordinates[1]}+${geometry.coordinates[0]}&oq=${geometry.coordinates[1]}++${geometry.coordinates[0]}`;

    const naverMapSearch = `https://map.naver.com/p/search/${geometry.coordinates[1]}%20${geometry.coordinates[0]}`;
    const weatherMap = `https://www.windy.com/${geometry.coordinates[1]}/${geometry.coordinates[0]}?${geometry.coordinates[1]},${geometry.coordinates[0]},10`;

    layer.bindPopup(`
      <a class="bind-popup" href=${naverMapSearch} target="_blank" rel="noreferrer">
        <p>네이버 지도로 보기</p>
      </a>
      <a class="bind-popup" href=${weatherMap} target="_blank" rel="noreferrer">
        <p>날씨 상세 정보</p>
      </a>
    `);
  };

  if (locationStatus === "pending" || locationIsFetching) {
    return (
      <Styles.Loading>
        <Loading {...atom} />
      </Styles.Loading>
    );
  } else if (locationStatus === "error" || !data.geometry) {
    // status -> success, loading, error...
    return (
      <ErrorPage error={locationError ? locationError : "No Location Data"} />
    );
  } else {
    return (
      <Styles.MapView center={center} zoom={map.zoom} scrollWheelZoom={true}>
        <TileLayer attribution={map.attribution} url={map.tileUrl} />
        <ResizeMap center={center} modalOpen={modalOpen} zoom={map.zoom} />
        <GeoJSON
          data={data && (data as GeoJsonObject)}
          onEachFeature={(feature, layer) => handlePopup(feature, layer)}
          pointToLayer={(_, latlng) => {
            return L.marker(latlng, {
              icon:
                type === "buoy" ? customIcon.buoyIcon : customIcon.portableIcon,
            });
          }}
        />
      </Styles.MapView>
    );
  }
}
