import { UseMutateFunction } from "@tanstack/react-query";
import { FormInstance } from "antd";
import { AxiosResponse } from "axios";
import { LatLngExpression } from "leaflet";
import { IDetail } from "../../../config/interfaces";
import {
  DetailTableSection,
  DetailTitleSection,
  MapViewModal,
  TimelineModal,
} from "../../molecule";

interface ITemplate extends IDetail {
  template: any;
  errorMessage?: string;
  setErrorMessage?: React.Dispatch<React.SetStateAction<string>>;
  deleteNoteData?: UseMutateFunction<AxiosResponse<any, any>, any, any, any>;
  historyData?: any;
  modalOpen?: boolean;
  totalDataLength?: number;
  handleOk?: () => void;
  handleCancel?: () => void;
  form?: FormInstance<any>;
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  setMapModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  locationStatus?: any;
  locationError?: any;
  center?: LatLngExpression;
  locationData?: any;
  locationIsFetching?: boolean;
  mapModalOpen?: boolean;
}

export default function DetailTemplate({
  template,
  allOpen,
  rows,
  dropdown,
  handleDropdown,
  status,
  error,
  fetching,
  deleteNoteData,
  historyData,
  modalOpen,
  totalDataLength,
  handleOk,
  handleCancel,
  form,
  pageNum,
  setPageNum,
  setMapModalOpen,
  locationStatus,
  locationError,
  locationData,
  center,
  locationIsFetching,
  mapModalOpen,
}: ITemplate) {
  return (
    <div {...template.container}>
      <TimelineModal
        {...template.molecule}
        deleteNoteData={deleteNoteData}
        historyData={historyData}
        modalOpen={modalOpen}
        totalDataLength={totalDataLength}
        handleOk={handleOk}
        handleCancel={handleCancel}
        form={form}
        pageNum={pageNum}
        setPageNum={setPageNum}
      />

      <MapViewModal
        handleOk={() => setMapModalOpen && setMapModalOpen(false)}
        handleCancel={() => setMapModalOpen && setMapModalOpen(false)}
        modalOpen={mapModalOpen}
        locationStatus={locationStatus}
        locationError={locationError}
        locationData={locationData}
        center={center}
        locationIsFetching={locationIsFetching}
        {...template}
      />

      <DetailTitleSection {...template.molecule} allOpen={allOpen} />

      <DetailTableSection
        {...template.molecule}
        rows={rows}
        dropdown={dropdown}
        handleDropdown={handleDropdown}
        status={status}
        error={error}
        fetching={fetching}
        setModalOpen={setMapModalOpen}
      />
    </div>
  );
}
