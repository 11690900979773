import {
  ActionBattery,
  ActionBoard,
  ActionEthernet,
  ActionHome,
  ActionMCU,
  ActionModem,
  ActionMppt,
  ActionMultiparameter,
  ActionSensor,
  ActionSim,
  ActionSolarPanel,
  DisableBattery,
  DisableBoard,
  DisableEthernet,
  DisableHome,
  DisableMCU,
  DisableModem,
  DisableMppt,
  DisableMultiparameter,
  DisableSensor,
  DisableSim,
  DisableSolarPanel,
} from "./config/image";
import { IPathItem } from "./config/interfaces";
import Battery from "./page/Battery/Battery";
import Board from "./page/Board/Board";
import Device from "./page/Device/Device";
import Ethernet from "./page/Ethernet/Ethernet";
import MCU from "./page/MCU/MCU";
import Modem from "./page/Modem/Modem";
import Mppt from "./page/Mppt/Mppt";
import Multiparameter from "./page/Multiparameter/Multiparameter";
import Sensor from "./page/Sensor/Sensor";
import Sim from "./page/Sim/Sim";
import SolarPanel from "./page/SolarPanel/SolarPanel";

export const path: IPathItem[] = [
  {
    path: "/",
    element: Device,
    pageName: "Device",
    actionIcon: ActionHome,
    disableIcon: DisableHome,
  },
];

export const subPath: IPathItem[] = [
  {
    path: "/mppt",
    element: Mppt,
    pageName: "MPPT",
    actionIcon: ActionMppt,
    disableIcon: DisableMppt,
    children: [
      {
        path: "/mppt/battery",
        element: Battery,
        pageName: "Battery",
        actionIcon: ActionBattery,
        disableIcon: DisableBattery,
      },
      {
        path: "/mppt/solarpanel",
        element: SolarPanel,
        pageName: "Solar Panel",
        actionIcon: ActionSolarPanel,
        disableIcon: DisableSolarPanel,
      },
    ],
  },
  {
    path: "/board",
    element: Board,
    pageName: "Board",
    actionIcon: ActionBoard,
    disableIcon: DisableBoard,
    children: [
      {
        path: "/board/mcu",
        element: MCU,
        pageName: "MCU",
        actionIcon: ActionMCU,
        disableIcon: DisableMCU,
      },
      {
        path: "/board/ethernet",
        element: Ethernet,
        pageName: "Ethernet",
        actionIcon: ActionEthernet,
        disableIcon: DisableEthernet,
      },
      {
        path: "/board/modem",
        element: Modem,
        pageName: "Modem",
        actionIcon: ActionModem,
        disableIcon: DisableModem,
      },
      {
        path: "/board/sim",
        element: Sim,
        pageName: "Sim",
        actionIcon: ActionSim,
        disableIcon: DisableSim,
      },
    ],
  },
  {
    path: "/multiparameter",
    element: Multiparameter,
    pageName: "Multiparameter",
    actionIcon: ActionMultiparameter,
    disableIcon: DisableMultiparameter,
    children: [
      {
        path: "/multiparameter/sensor",
        element: Sensor,
        pageName: "Sensor",
        actionIcon: ActionSensor,
        disableIcon: DisableSensor,
      },
    ],
  },
];
