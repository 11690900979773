import RouterIcon from "@mui/icons-material/Router";
import { colorPallete } from "../../../../config";

export function ActionModem() {
  return <RouterIcon style={{ color: "white" }} />;
}

export function DisableModem() {
  return <RouterIcon style={{ color: colorPallete.gray }} />;
}
