import { LatLngExpression } from "leaflet";
import { ISearchParams, ITable } from "../../../config/interfaces";
import { MapViewModal, TableSection, TitleSection } from "../../molecule";

interface ITemplate extends ITable {
  template: any;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setAddMode: React.Dispatch<React.SetStateAction<boolean>>;
  searchFilter?: string;
  setSearchFilter?: React.Dispatch<React.SetStateAction<string>>;
  searchParams: ISearchParams[];
  setSearchResult?: React.Dispatch<React.SetStateAction<string>>;
  modalOpen?: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
  locationStatus?: any;
  locationError?: any;
  center?: LatLngExpression;
  locationData?: any;
  locationIsFetching?: boolean;
}

export default function TableTemplate({
  template,
  errorMessage,
  setErrorMessage,
  handleAdd,
  form,
  initialRow,
  newData,
  addData,
  editingKey,
  addMode,
  fetching,
  status,
  error,
  setAddMode,
  searchFilter,
  setSearchFilter,
  searchParams,
  setSearchResult,
  tableChildren,
  modalOpen,
  handleOk,
  handleCancel,
  locationStatus,
  locationError,
  locationData,
  center,
  locationIsFetching,
}: ITemplate) {
  return (
    <div {...template.container}>
      <MapViewModal
        handleOk={handleOk}
        handleCancel={handleCancel}
        modalOpen={modalOpen}
        locationStatus={locationStatus}
        locationError={locationError}
        locationData={locationData}
        center={center}
        locationIsFetching={locationIsFetching}
        {...template}
      />

      <TitleSection
        {...template.molecule}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />

      <TableSection
        {...template.molecule}
        setErrorMessage={setErrorMessage}
        handleAdd={handleAdd}
        form={form}
        initialRow={initialRow}
        newData={newData}
        addData={addData}
        editingKey={editingKey}
        addMode={addMode}
        fetching={fetching}
        status={status}
        error={error}
        setAddMode={setAddMode}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        searchParams={searchParams}
        setSearchResult={setSearchResult}
        tableChildren={tableChildren}
      />
    </div>
  );
}
