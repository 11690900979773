import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import { colorPallete } from "../../../../config";

export function ActionDashboard() {
  return <SpaceDashboardIcon style={{ color: "white" }} />;
}

export function DisableDashboard() {
  return <SpaceDashboardIcon style={{ color: colorPallete.gray }} />;
}
