import { Form } from "antd";
import { LatLngExpression } from "leaflet";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useBuoyDetail,
  useBuoyLocation,
  useBuoyNote,
  useTotalDevice,
} from "../../api/getData";
import { useAddNote, useDeleteNote } from "../../api/mutationData";
import { DetailTemplate } from "../../components/template";
import { detailProps } from "./data";

export default function DeviceDetail() {
  const location = useLocation();
  const { state } = location;
  const queryKey = location.pathname;

  const path = "device" + queryKey.replace("device", "detail");
  const deviceID = queryKey.split("/")[2];

  const serial_number = state?.device;

  const [form] = Form.useForm();

  const [errorMessage, setErrorMessage] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [mapModalOpen, setMapModalOpen] = useState(false);

  const { status, data, error, isFetching, refetch }: any = useBuoyDetail(
    path,
    queryKey
  );

  const { mutate: addNoteData } = useAddNote(
    setErrorMessage,
    `${queryKey}/note`
  );
  const { mutate: deleteNoteData } = useDeleteNote(
    setErrorMessage,
    `${queryKey}/note`
  );
  const {
    status: locationStatus,
    data: locationData,
    refetch: locationRefetch,
    error: locationError,
    isFetching: locationIsFetching,
  }: any = useBuoyLocation(
    `iot/${serial_number}`,
    `iot/${serial_number}`,
    mapModalOpen
  );

  const center: LatLngExpression = [
    locationData?.geometry?.coordinates[1],
    locationData?.geometry?.coordinates[0],
  ];

  const {
    data: noteData,
    isFetching: noteIsFetching,
    refetch: noteRefetch,
  }: any = useBuoyNote(pageNum, `note/${deviceID}`, `${queryKey}/note`);

  const { data: totalData, refetch: totalRefetch }: any = useTotalDevice(
    `note/${deviceID}`
  );

  const [rows, setRows] = useState(data);
  const [historyData, setHistoryData] = useState(noteData);
  const [dropdown, setDropdown] = useState([
    "Device",
    "MPPT",
    "Battery",
    "Solar Panel",
    "Board",
    "MCU",
    "Ethernet",
    "Modem",
    "Sim",
    "Multiparameter",
    "Sensor",
  ]);
  const [allOpen, setAllOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [totalDataLength, setTotalDataLength] = useState(10);

  useEffect(() => {
    setRows(isFetching ? [] : data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  useEffect(() => {
    setHistoryData(noteIsFetching ? [] : noteData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteIsFetching]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    noteRefetch();
  }, [noteRefetch, pageNum]);

  useEffect(() => {
    if (totalData % 10 !== 0) {
      setTotalDataLength(Math.floor(totalData / 10 + 1) * 10);
      totalRefetch();
    } else {
      setTotalDataLength(Math.floor(totalData / 10) * 10);
      setPageNum(1);
      totalRefetch();
    }
  }, [totalData, totalRefetch, noteIsFetching]);

  useEffect(() => {
    if (mapModalOpen) locationRefetch();
  }, [locationRefetch, mapModalOpen]);

  const handleDropdown = (title: string) => {
    if (dropdown.includes(title)) {
      setDropdown(dropdown.filter((item) => item !== title));
    } else {
      setDropdown([...dropdown, title]);
    }
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const data = { ...values, device_id: Number(deviceID) };
        addNoteData(data);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setPageNum(1);
    setModalOpen(false);
  };

  detailProps.template.molecule.atom.mainTitle.children = `Device ID: ${
    queryKey.split("/")[2]
  }`;

  detailProps.template.molecule.atom.expandButton.onClick = () => {
    setDropdown(rows.map((res: any) => res.info));
    setAllOpen(true);
  };

  detailProps.template.molecule.atom.foldButton.onClick = () => {
    setDropdown([]);
    setAllOpen(false);
  };

  detailProps.template.molecule.atom.historyButton.onClick = () => {
    showModal();
  };

  return (
    <DetailTemplate
      {...detailProps}
      status={status}
      error={error}
      fetching={isFetching}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      allOpen={allOpen}
      rows={rows}
      dropdown={dropdown}
      handleDropdown={handleDropdown}
      deleteNoteData={deleteNoteData}
      historyData={historyData}
      modalOpen={modalOpen}
      totalDataLength={totalDataLength}
      handleOk={handleOk}
      handleCancel={handleCancel}
      form={form}
      pageNum={pageNum}
      setPageNum={setPageNum}
      setMapModalOpen={setMapModalOpen}
      locationStatus={locationStatus}
      locationError={locationError}
      locationData={locationData}
      center={center}
      locationIsFetching={locationIsFetching}
      mapModalOpen={mapModalOpen}
    />
  );
}
