import { CSSProperties } from "react";

interface ITitle {
  title?: {
    style?: CSSProperties;
    children: string;
  };
  style?: CSSProperties;
  children?: string;
  onClick?: () => void;
}

export default function Title({ title, style, children, onClick }: ITitle) {
  return style && children ? (
    <p style={style} children={children} onClick={onClick} />
  ) : (
    <p {...title} />
  );
}
