/**
 * sm -> 12
 *
 * base -> 14
 *
 * lg -> 16
 *
 * xl -> 18
 *
 * xl1 -> 20
 *
 * xl2 -> 34
 *
 * xl3 -> 37
 */
export const fontSize = {
  sm: 12, // -> 12(15)
  base: 14, // -> 14(17)
  lg: 16, // -> 16(18)
  xl: 18, // -> 18(20)
  xl1: 20, // -> 20(21)
  xl2: 34, // -> 34(35)
  xl3: 37, // -> 37(39)
};

export const colorPallete = {
  primary: "#1D60BC",
  orange: "#FFA857",
  red: "#FF5757",
  green: "#00C975",
  gray: "#A3A3A3",
  side: "#282C34",
  white: "#EFF3F8",
};

export const buttonStyle = {
  borderRadius: ".5rem",
  marginLeft: ".1875rem",
  marginRight: ".1875rem",
};

export const titleStyle = {
  fontWeight: "bolder",
  fontSize: "2.5rem",
  marginTop: 25,
  marginBottom: 20,
};
