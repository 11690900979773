import axios, { AxiosResponse } from "axios";

export const baseURL = "https://api.device.odn.us";
// export const baseURL = "http://172.30.1.12:8000";

const buoyClient = axios.create({
  baseURL: "https://api.device.odn.us",
  // baseURL: "http://172.30.1.12:8000",
});

export const buoyRequest = async ({ ...options }) => {
  const onSuccess = (res: AxiosResponse) => res;
  const onError = (error: any) => {
    return error;
  };

  try {
    const res = await buoyClient(options);
    return onSuccess(res);
  } catch (error) {
    return onError(error);
  }
};
