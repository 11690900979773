import { Flex } from "antd";
import { colorPallete } from "../../../config";
import { Cancel, Check, Delete, Update } from "../../../config/image";
import Button from "../../atom/Button";

const buttonProps = {
  save: {
    tooltip: {
      title: "저장",
      placement: "bottom" as "bottom",
    },
    iconButton: {
      style: {
        backgroundColor: colorPallete.green,
        border: "none",
      },
      icon: <Check />,
      onClick: () => console.log("save"),
    },
  },
  edit: {
    tooltip: {
      title: "수정",
      placement: "bottom" as "bottom",
    },
    iconButton: {
      style: {
        backgroundColor: colorPallete.orange,
        border: "none",
      },
      icon: <Update />,
      onClick: () => console.log("edit"),
    },
  },
  delete: {
    popconfirm: {
      title: "삭제하시겠습니까?",
      onConfirm: () => console.log("delete"),
    },
    tooltip: {
      title: "삭제",
      placement: "bottom" as "bottom",
    },
    iconButton: {
      style: {
        backgroundColor: colorPallete.red,
        border: "none",
      },
      icon: <Delete />,
      onClick: () => console.log("delete"),
    },
  },
  cancel: {
    tooltip: {
      title: "취소",
      placement: "bottom" as "bottom",
    },
    iconButton: {
      style: {
        backgroundColor: colorPallete.orange,
        border: "none",
      },
      icon: <Cancel />,
      onClick: () => console.log("cancel"),
    },
  },
};

interface ITableButtons {
  isEditing: (record: Partial<any>) => boolean;
  record: any;
  editingKey: React.SetStateAction<string | number>;
  path: string;
  deleteData: any;
  edit: (record: any) => void;
  save: (key: React.Key) => Promise<void>;
  cancel: () => void;
  handleDelete({ path, serial_number, deleteData }: any): void;
}

export default function TableButtons({
  isEditing,
  record,
  editingKey,
  path,
  deleteData,
  edit,
  save,
  cancel,
  handleDelete,
}: ITableButtons) {
  const editable = isEditing(record);

  buttonProps.save.iconButton.onClick = () => save(record.key);
  buttonProps.edit.iconButton.onClick = () => edit(record);
  buttonProps.delete.popconfirm.onConfirm = () => {
    const serial_number = record.serial_number
      ? record.serial_number
      : record.id;
    handleDelete({ path, serial_number, deleteData });
  };
  buttonProps.cancel.iconButton.onClick = cancel;

  buttonProps.edit.iconButton.style.backgroundColor =
    editingKey !== "" ? colorPallete.gray : colorPallete.orange;

  return editable ? (
    <Flex gap="small" wrap="wrap" align="center" justify="center">
      <Button {...buttonProps.save} />
      <Button {...buttonProps.cancel} />
    </Flex>
  ) : (
    <Flex gap="small" wrap="wrap" align="center" justify="center">
      <Button {...buttonProps.edit} />
      <Button {...buttonProps.delete} />
    </Flex>
  );
}
