import HelpIcon from "@mui/icons-material/Help";
import { colorPallete } from "../../../../config";

export function ActionManual() {
  return <HelpIcon style={{ color: "white" }} />;
}

export function DisableManual() {
  return <HelpIcon style={{ color: colorPallete.gray }} />;
}
