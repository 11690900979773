import { queryOptions } from "@tanstack/react-query";

interface IOption {
  (queryKey: (string | number | boolean)[], queryFn: () => Promise<any>): any;
}

interface IData {
  // data: {
  //   results?: any[];
  // };
  data: {
    total: number;
    page: number;
    size: number;
    results: any[];
  };
}

interface IDetailData {
  info: string;
  data: any;
}

export const queryCommonOptions: IOption = (queryKey, queryFn) => {
  return queryOptions({
    queryKey: queryKey,
    queryFn: queryFn,
    staleTime: 1 * 60 * 1000, // 1분
    refetchOnWindowFocus: true, // 다른 창을 갔다가 돌아왔을 시, refetch
    refetchOnMount: true,
    retry: 2, // error시 fetch 재시도
    // refetchInterval: 5000, // polling (시간에 따라 refetch)
    // refetchIntervalInBackground: false,
    // enabled: false,
    select: (data: IData) => {
      const allData = data?.data.results.map((res) => ({
        ...res,
        key: res.id,
      }));
      return allData;
    },
  });
};

export const queryGetTotalOptions: IOption = (queryKey, queryFn) => {
  return queryOptions({
    queryKey: queryKey,
    queryFn: queryFn,
    staleTime: 1 * 60 * 1000, // 1분
    // refetchOnWindowFocus: true, // 다른 창을 갔다가 돌아왔을 시, refetch
    // refetchOnMount: true,
    retry: 2, // error시 fetch 재시도
    select: (data: IData) => {
      const allData = data?.data.total;
      return allData;
    },
  });
};

export const queryDetailOptions: IOption = (queryKey, queryFn) => {
  return queryOptions({
    queryKey: queryKey,
    queryFn: queryFn,
    staleTime: 1 * 60 * 1000, // 1분
    refetchOnWindowFocus: true, // 다른 창을 갔다가 돌아왔을 시, refetch
    refetchOnMount: true,
    retry: 2, // error시 fetch 재시도
    // refetchInterval: 5000, // polling (시간에 따라 refetch)
    // refetchIntervalInBackground: false,
    // enabled: false,
    select: (data: IDetailData) => {
      const defaultData = data.data.device;
      const allData = [
        { info: "Device", data: defaultData },
        { info: "MPPT", data: defaultData.mppt && defaultData.mppt },
        { info: "Battery", data: defaultData.battery && defaultData.battery },
        {
          info: "Solar Panel",
          data: defaultData.solarpanel && defaultData.solarpanel,
        },
        { info: "Board", data: defaultData.board && defaultData.board },
        { info: "MCU", data: defaultData.board?.mcu && defaultData.board?.mcu },
        {
          info: "Ethernet",
          data: defaultData.board?.ethernet && defaultData.board?.ethernet,
        },
        {
          info: "Modem",
          data: defaultData.board?.modem && defaultData.board?.modem,
        },
        {
          info: "Sim",
          data: defaultData.board?.modem && defaultData.board?.sim,
        },
        {
          info: "Multiparameter",
          data: defaultData.multiparameter && defaultData.multiparameter,
        },
        {
          info: "Sensor",
          data:
            defaultData.multiparameter?.sensor &&
            defaultData.multiparameter.sensor,
        },
      ];

      return allData;
    },
  });
};

export const queryGetNoteOptions: IOption = (queryKey, queryFn) => {
  return queryOptions({
    queryKey: queryKey,
    queryFn: queryFn,
    staleTime: 1 * 60 * 1000, // 1분
    // refetchOnWindowFocus: true, // 다른 창을 갔다가 돌아왔을 시, refetch
    // refetchOnMount: true,
    retry: 2, // error시 fetch 재시도
    select: (data: IData) => {
      const allData = data?.data.results;
      return allData;
    },
  });
};

export const queryGetLocationOptions: IOption = (queryKey, queryFn) => {
  return queryOptions({
    queryKey: queryKey,
    queryFn: queryFn,
    // staleTime: 1 * 60 * 1000, // 1분
    // refetchOnWindowFocus: true, // 다른 창을 갔다가 돌아왔을 시, refetch
    // refetchOnMount: true,
    retry: 2, // error시 fetch 재시도
    enabled: !!queryKey[1],
    select: (data: any) => {
      const allData = data?.data;
      return allData;
    },
  });
};
