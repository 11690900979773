import { UseMutateFunction } from "@tanstack/react-query";
import { FormInstance, Modal, Pagination, Timeline } from "antd";
import { AxiosResponse } from "axios";
import { colorPallete } from "../../../config";
import History from "../Timeline/History";
import NewHistory from "../Timeline/NewHistory";

interface ITimelineModal {
  deleteNoteData?: UseMutateFunction<AxiosResponse<any, any>, any, any, any>;
  historyData?: any;
  modalOpen?: boolean;
  totalDataLength?: number;
  handleOk?: () => void;
  handleCancel?: () => void;
  form?: FormInstance<any>;
  atom: any;
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
}

interface IModalPagination {
  OkBtn: React.FC;
  CancelBtn: React.FC;
  totalDataLength?: number;
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
}

const ModalPagination = ({
  OkBtn,
  CancelBtn,
  totalDataLength,
  pageNum,
  setPageNum,
}: IModalPagination) => (
  <div style={{ display: "flex", justifyContent: "space-between" }}>
    <Pagination
      style={{ display: "inline-flex" }}
      current={pageNum}
      onChange={(value) => {
        setPageNum(value);
      }}
      total={totalDataLength}
    />
    <div style={{ display: "flex", gap: 10 }}>
      <OkBtn />
      <CancelBtn />
    </div>
  </div>
);

export default function TimelineModal({
  deleteNoteData,
  historyData,
  modalOpen,
  totalDataLength,
  handleOk,
  handleCancel,
  form,
  atom,
  pageNum,
  setPageNum,
}: ITimelineModal) {
  const timelineItems = historyData?.map((res: any) => {
    return {
      children: <History res={res} {...atom} deleteNoteData={deleteNoteData} />,
    };
  });

  const newItem = {
    color: colorPallete.orange,
    children: <NewHistory form={form} {...atom} />,
  };

  return (
    <Modal
      open={modalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      {...atom.modalItem.modal}
      footer={(_, { OkBtn, CancelBtn }) => (
        <ModalPagination
          OkBtn={OkBtn}
          CancelBtn={CancelBtn}
          totalDataLength={totalDataLength}
          pageNum={pageNum}
          setPageNum={setPageNum}
        />
      )}>
      <Timeline {...atom.modalItem.newTimeline} items={[newItem]} />
      <Timeline
        {...atom.modalItem.timeline}
        items={
          timelineItems?.length === 0
            ? [
                {
                  color: "gray",
                  children: (
                    <div>
                      <p>No Content</p>
                    </div>
                  ),
                },
              ]
            : timelineItems
        }
      />
    </Modal>
  );
}
