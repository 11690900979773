import SimCardIcon from "@mui/icons-material/SimCard";
import { colorPallete } from "../../../../config";

export function ActionSim() {
  return <SimCardIcon style={{ color: "white" }} />;
}

export function DisableSim() {
  return <SimCardIcon style={{ color: colorPallete.gray }} />;
}
