import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  queryCommonOptions,
  queryDetailOptions,
  queryGetLocationOptions,
  queryGetNoteOptions,
  queryGetTotalOptions,
} from "../config/apiCommon";
import { baseURL } from "../util/axiosUtil";

const getBuoyUrl = async (
  pageNum: number,
  path: string,
  searchFilter?: string
) => {
  return await axios({
    baseURL: `${baseURL}`,
    url: `/${path}?page=${pageNum}${searchFilter ? searchFilter : ""}`,
  });
};

const getTotalUrl = async (url: string) => {
  return await axios({ baseURL: `${baseURL}`, url: `/${url}` });
};

const getBuoyDetailUrl = async (path: string) => {
  return await axios({
    baseURL: `${baseURL}`,
    url: `/${path}`,
  });
};

const getBuoyLocationUrl = async (path: string) => {
  return await axios({
    baseURL: `${baseURL}`,
    url: `/${path}`,
  });
};

export const useBuoy = (
  pageNum: number,
  path: string,
  queryKey: string,
  searchFilter?: string
) => {
  return useQuery(
    queryCommonOptions([`${queryKey}`], () =>
      getBuoyUrl(pageNum, path, searchFilter)
    )
  );
};

export const useBuoyNote = (
  pageNum: number,
  path: string,
  queryKey: string
) => {
  return useQuery(
    queryGetNoteOptions([`${queryKey}`], () => getBuoyUrl(pageNum, path))
  );
};

export const useTotalDevice = (url: string) => {
  return useQuery(queryGetTotalOptions(["total"], () => getTotalUrl(url)));
};

export const useBuoyDetail = (path: string, queryKey: string) => {
  return useQuery(
    queryDetailOptions([`${queryKey}`], () => getBuoyDetailUrl(path))
  );
};

export const useBuoyLocation = (
  path: string,
  queryKey: string,
  modalOpen: boolean
) => {
  return useQuery(
    queryGetLocationOptions([`${queryKey}`, modalOpen], () =>
      getBuoyLocationUrl(path)
    )
  );
};
