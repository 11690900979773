import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import { colorPallete } from "../../../../config";

export function ActionBattery() {
  return <BatteryChargingFullIcon style={{ color: "white" }} />;
}

export function DisableBattery() {
  return <BatteryChargingFullIcon style={{ color: colorPallete.gray }} />;
}
