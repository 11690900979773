import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IPathItem } from "./config/interfaces";
import DeviceDetail from "./page/DeviceDetail/DeviceDetail";
import Sidebar from "./page/Sidebar/Sidebar";
import { path, subPath } from "./path";

function Router() {
  return (
    <BrowserRouter>
      <div style={{ display: "flex" }}>
        <Sidebar />

        <Routes>
          {path.map((res) => (
            <Route key={res.path} path={res.path} element={<res.element />} />
          ))}

          <Route path={"/device/:id"} element={<DeviceDetail />} />

          {subPath.map((res) => (
            <Route key={res.path} path={res.path} element={<res.element />} />
          ))}

          {subPath.map((res: IPathItem) =>
            res.children?.map((children) => (
              <Route
                key={children.path}
                path={children.path}
                element={<children.element />}
              />
            ))
          )}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default Router;
