import { Badge } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { TableButtons } from "../../components/molecule";
import { IColumns } from "../../config/interfaces";
import { handleDelete } from "../../util/tableUtil";
import { ISensor } from "./interface";

export default function Columns({
  isEditing,
  save,
  cancel,
  editingKey,
  edit,
  deleteData,
  rows,
  path,
  addMode,
}: IColumns) {
  return [
    {
      title: "ID",
      dataIndex: "key",
      key: "key",
      width: "5%",
      editable: false,
      sorter: {
        compare: (a: ISensor, b: ISensor) => Number(a.key) - Number(b.key),
      },
    },
    {
      title: "시리얼 번호",
      dataIndex: "serial_number",
      key: "serial_number",
      editable: true,
    },
    {
      title: "보증 기간",
      dataIndex: "warranty",
      key: "warranty",
      editable: true,
      render: (_: any, record: ISensor) => {
        const localeDate = dayjs(record.warranty).locale("ko");
        const formattingDate = localeDate.format("YYYY-MM-DD");

        return `${formattingDate}`;
      },
    },
    {
      title: "제품명",
      dataIndex: "name",
      key: "name",
      editable: true,
    },
    {
      title: "제품 상태",
      dataIndex: "state",
      key: "state",
      editable: true,
      render: (_: any, record: ISensor) => (
        <Badge
          size="default"
          status={record.state ? "success" : "error"}
          text={record.state ? "ON" : "OFF"}
        />
      ),
    },
    {
      title: "Multiparameter 시리얼 번호",
      dataIndex: "fk_serial_number",
      key: "fk_serial_number",
      editable: true,
      addMode: addMode,
      render: (_: any, record: ISensor) => record.multiparameter?.serial_number,
    },
    {
      title: "편집",
      dataIndex: "Action",
      width: "6%",
      align: "center" as const,
      render: (_: any, record: ISensor) => (
        <TableButtons
          isEditing={isEditing}
          record={record}
          editingKey={editingKey}
          path={path}
          deleteData={deleteData}
          edit={edit}
          save={save}
          cancel={cancel}
          handleDelete={handleDelete}
        />
      ),
    },
  ];
}
