import { Collapse } from "@mui/material";
import React from "react";
import { IMoleculeProps } from "../../../page/Sidebar/interface";
import { Anchor } from "../../atom";

// const Styles = {
//   TitleSection: styled.div<ITitleSection>`
//     ${(props) => props.style}
//   `,
//   Ul: styled.ul<IUl>`
//     ${(props) => props.style}
//   `,
//   SubUl: styled(ListItemButton)<IUl>`
//     ${(props) => props.style};
//     ${(props) =>
//       props.$pathname !== ("/" || "/device") ? {} : { display: "none" }};
//   `,
// };

export default function Anchors({
  pathname,
  path,
  subPath,
  anchor,
}: IMoleculeProps) {
  const mainPathArray = pathname.split("/", 2);
  const mainPath = mainPathArray[1];

  return (
    <React.Fragment>
      <Anchor {...anchor.path.manualPath} />
      <Anchor {...anchor.path.dashboardPath} />

      {path.map((res) => (
        <Anchor
          key={res.path}
          pathname={pathname}
          to={res.path}
          pageName={res.pageName}
          anchor={anchor}
          actionIcon={res.actionIcon}
          disableIcon={res.disableIcon}
          {...anchor.path.mainPath}
        />
      ))}

      {subPath.map((res) => (
        <Collapse key={res.path} in={true}>
          <Anchor
            pathname={pathname}
            to={res.path}
            anchor={anchor}
            pageName={res.pageName}
            actionIcon={res.actionIcon}
            disableIcon={res.disableIcon}
            {...anchor.path.subPath}
          />
          {res.children?.map((children) => {
            let childAnchor;
            if (
              mainPath === "multiparameter" &&
              res.path === "/multiparameter"
            ) {
              childAnchor = "multiparameter";
            } else if (mainPath === "board" && res.path === "/board") {
              childAnchor = "board";
            } else if (mainPath === "mppt" && res.path === "/mppt") {
              childAnchor = "mppt";
            }

            return (
              <Collapse
                key={children.path}
                in={
                  childAnchor === "multiparameter"
                    ? true
                    : childAnchor === "board"
                    ? true
                    : childAnchor === "mppt"
                    ? true
                    : false
                }>
                <Anchor
                  pathname={pathname}
                  to={children.path}
                  anchor={anchor}
                  pageName={children.pageName}
                  actionIcon={children.actionIcon}
                  disableIcon={children.disableIcon}
                  {...anchor.path.childPath}
                />
              </Collapse>
            );
          })}
        </Collapse>
      ))}
    </React.Fragment>
  );
}
